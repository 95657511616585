import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../../../Style/Registro/validacion.css";
import '../../../Style/generales/button-retroceso.css';
import { useNavigate } from 'react-router-dom';
import flecha from "../../../Image/generales/flecha-atras-26.png";
import { FiAlertCircle } from "react-icons/fi";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import useMediaQuery from '@mui/material/useMediaQuery';

export const Validacion = ({  email }) => {

  const [codigo, setCodigo] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;


  const handleChangeCodigo = (event) => {
    setCodigo(event.target.value);
    setError("");
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${backendURL}/api/activeUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          codigo,
          email,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error al activar el usuario');
      }
  
      const data = await response.json();
      navigate('/');
      console.log(data); // Manejar la respuesta del backend, por ejemplo, mostrar un mensaje de éxito al usuario
    } catch (error) {
      console.error(error);
      setError('Error al activar el usuario');
    }
  };

  const handleResendCode = async () => {
    handleOpenAlert();
    try {
      const response = await fetch(`${backendURL}/api/resendActivationCode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error al reenviar el código');
      }
  
      const data = await response.json();
      
    } catch (error) {
      console.error(error);
      setError('Error al reenviar el código');
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  

  useEffect(() => {
    if (openAlert) {
      // Establecer un temporizador para cerrar el modal después de dos segundos
      const timer = setTimeout(() => {
        handleCloseAlert();
      }, 2000); // 2000 milisegundos = 2 segundos
  
      // Limpiar el temporizador al desmontar el componente o al actualizar openAlert
      return () => clearTimeout(timer);
    }
  }, [openAlert]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const isSmallScreen = useMediaQuery('(max-width: 400px)');

  // Establecer el valor de maxWidth en función del tamaño de la pantalla
  const maxWidth = isSmallScreen ? 200 : 400;




  return (
    <>
      <section className="registro-cuerpo-inicio">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
            marginTop:"40px",
            padding:"0px 10px"
          }}
        >
         

          <section className="titulo-validacion">
            <h1>Hemos enviado un</h1>
            <h1>código de confirmación</h1>
            <h1>al correo electrónico</h1>
            <hr style={{ border: '0px', padding: '10px' }} />
            <h1><strong>{email}</strong></h1>
          </section>

          <section className="validar-codigo">
            <h1>Ingrese el código</h1>
            <input className={`validacion-input ${error && 'input-error'}`}  value={codigo} onChange={handleChangeCodigo} />
            {error && <span className="error-message"><FiAlertCircle className='icon-error' />{error}</span>}
            <span>¿No recibió el código?</span>
           <button style={{background:'white', border:'0px'}} onClick={handleResendCode}> <p  >Reenviar correo</p></button>
          </section>
          <Modal
  open={openAlert}
  onClose={handleCloseAlert}
  aria-labelledby="alert-modal-title"
  aria-describedby="alert-modal-description"
>
<Box
              sx={{
                position: "absolute",
                width: '100%',
                maxWidth: maxWidth,
                bgcolor: "transparent",
                border: "0px",
                p: 4,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Alert severity="success" variant="filled" sx={{ backgroundColor: '#431E62', width:'100%' }}>
                Código enviado correctamente
              </Alert>
            </Box>
</Modal>

          <div className="boton-registro">
            <button onClick={handleSubmit}>Confirmar</button>
          </div>
        </Box>
      </section>
    </>
  );
};
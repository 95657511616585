import React, {useRef, useEffect} from "react";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import "../../../Style/Registro/Registro-inicio.css";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
/* imagenes */
import gif from "../../../Image/video/memorial-intro.gif"


export const Registro = () => {
 
  return (
    
     
        <Container className="contieneregistro"component="main" maxWidth="sm" sx={{paddingLeft:'0px', paddingRight:'0px'}}>
          <Box
            sx={{
              width:"100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
               <img src={gif} alt="smart memorial" style={{width:'100%'}}/>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#ffff",
            }}
          >
            <div className="bienvenida-registro">
              <h1 className="primertitulo-registroinicio">Bienvenido a</h1>
              <h1 className="segundotitulo-registroinicio">Smart Memorial</h1>
            </div>

            <div className="informacion-activacion">
              <div className="tituloactivacion">
                <h2>Para activar tu servicio</h2>
                <h2>debes registrarte</h2>
              </div>
              <Link className="link" to='/verificacion-de-registro'>
              <div className="botoniinicioregistro">
                <button> Regístrarse</button>
              </div></Link>
              <div className="info-registro-inicio">
                <span>¿Ya tienes una cuenta?</span>{" "}
                <Link className="link" to="/">
                <div className="boton-inicio">
                <button>Iniciar sesión</button>
              </div>
                </Link>
              </div>
            </div>
             <div className="texto-des">
            <span>Mantengamos vivo el legado</span>
            <span>que nos han dejado...</span>
            </div>
        
          </Box>
          
           
        </Container>
    
 
  );
};

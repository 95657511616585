import React from "react";
import { Grid, Box, CircularProgress, Typography, Container, Modal } from "@mui/material";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../Style/usuario/evento.css";
import { FiAlertCircle } from "react-icons/fi";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";


export const UpdatePassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModals, setShowModals] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [inputError, setInputError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
    const [isOldPasswordCorrect, setIsOldPasswordCorrect] = useState(false);
    const [showNewPasswordFields, setShowNewPasswordFields] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const location = useLocation();
    const ClientToken = location.state.user;
    const grupoFamiliar = location.state.familia;
    const [grupoUser, setGrupoUser] = useState(null);
    const navigate = useNavigate();
    const [password, setPassword] = useState({
        old_password: "",
        password: "",
        new_password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassword({ ...password, [name]: value });

        if (name === 'old_password') {
            setInputError(false);
            setError('');
        }
    };


    useEffect(() => {
        const fetchGrupoUser = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getUserId/${ClientToken.userId}`, {
                    headers: {
                        Authorization: `Bearer ${ClientToken.token}`,
                    },
                });
                setGrupoUser(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching grupo familiar:', error);
            }
        };

        fetchGrupoUser();
    }, [ClientToken, backendURL]);

    const handleVerifyPassword = async () => {
        try {
            setenviandoAlert(true);
            setIsLoading(true);
            const response = await axios.post(
                `${backendURL}/api/verificarpassword`,
                { password: password.old_password },
                {
                    headers: {
                        Authorization: `Bearer ${ClientToken.token}`,
                    },
                }
            );
            setenviandoAlert(false);
            setIsLoading(false);
            setInputError(false);
            setError('');
            setShowModals(true);
            setIsOldPasswordCorrect(true);
            setShowNewPasswordFields(true);
        } catch (error) {
            setenviandoAlert(false);
            setIsLoading(false);
            setInputError(true);
            setError('Contraseña actual incorrecta');
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.new_password !== password.confirm_new_password) {
            setPasswordError('Las contraseñas no coinciden');
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        try {
            setenviandoAlert(true);
            const response = await axios.post(
                `${backendURL}/api/updatePasswordMenu`, 
                {
                    user_id: ClientToken.userId,
                    password: password.new_password,
                    confirm_password: password.confirm_new_password
                }, 
                {
                    headers: {
                        Authorization: `Bearer ${ClientToken.token}`
                    }
                }
            );
            setIsLoading(false);
            setShowModal(true);
            setenviandoAlert(false);
          setSuccessAlertOpen(true);
          setAlertMessage('Se ha actualizado correctamente su contraseña.');
        } catch (error) {
            setErrorAlertOpen(true);
          setAlertMessage('Error al actualizar su contraseña, vuelva a intentar.');
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
            setenviandoAlert(false);
        }
        setTimeout(() => {
            setSuccessAlertOpen(false);
            setErrorAlertOpen(false);
            navigate(`/usuariosmart`, { state: { user: ClientToken, familia: grupoFamiliar} });
        }, 3000);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        navigate(0);
    };
    const handleCloseModals = () => {
        setShowModals(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordcorrectVisibility = () => {
        setShowPasswordcorrect(!showPasswordcorrect);
    };


    const handleChangeContraseña = (event) => {
        const { value } = event.target;
        setPassword({ ...password, new_password: value });
        if (value.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
        } else {
            setPasswordError('');
        }
    };

    const handleChangeRepetirContraseña = (event) => {
        const { value } = event.target;
        setPassword({ ...password, confirm_new_password: value });
        if (value.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
        } else if (value !== password.new_password) {
            setPasswordError('Las contraseñas no coinciden');
        } else {
            setPasswordError('');
        }
    };


    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="30px">
                <CircularProgress color="secondary" />
            </Box>
        );
    }




    return (
        <>
        
                <Box sx={{ display:"flex", alignItems:"center", justifyContent:"center", }} >

                 
                        <Grid container s>
                            <Grid item xs={12} sx={{  marginBottom: '10px' }}>
                                <div className="inputcrearcontraseña">
                                    <label className="titulocontraseñalabel" htmlFor="old_password">Contraseña antigua</label>
                                    <input
                                        className={`crearinputcontraseña ${inputError ? 'input-error' : ''}`} // Agregar clase condicionalmente
                                        type="password"
                                        name="old_password"
                                        onChange={handleChange}
                                        value={password.old_password}
                                    />
                                    {inputError && <span className="mensajeerrorcontraseña"> <FiAlertCircle /> <p>{error}</p> </span>}
                                </div>
                            </Grid>
                            <Grid item xs={12} sx={{  marginBottom: '10px' }}>
                                <section className="botonguardarcambiar">
                            <button onClick={handleVerifyPassword} disabled={isLoading}>
                                {isLoading ? <CircularProgress size={24} /> : 'Cambiar'}
                            </button>
                        </section>
                            </Grid>

                        </Grid>

                         </Box>

                         {showNewPasswordFields && (
                            <Box >
                                <h1 className="titulocontraseñacorrecta">Cambiar contraseña</h1>
                                <form onSubmit={handleSubmit}>
                                    <Grid container sx={{ width: '100%' }}>
                                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}>
                                            <div className="inputcrearcontraseñanueva">
                                                <label className="titulocontraseñalabel" htmlFor="new_password">Contraseña nueva</label>
                                                <div className="contienentodocontraseñanueva">
                                                    <input className={`crearinputcontraseña ${error.password && 'input-error'} ${passwordError && 'input-error'}`} type={showPassword ? 'text' : 'password'} name="new_password" onChange={(e) => { handleChange(e); handleChangeContraseña(e); handleChangeContraseña(e) }} value={password.new_password} />
                                                    <span className="togglenuevopassword" onClick={togglePasswordVisibility}>
                                                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                {error.password && <span className="errorcontraseñamensaje"><FiAlertCircle className='icon-error' />{error.password}</span>}
                                                {passwordError && <span className="errorcontraseñamensaje"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}>
                                            <div className="inputcrearcontraseñanueva">
                                                <label className="titulocontraseñalabel" htmlFor="confirm_new_password">Repetir contraseña</label>
                                                <div className="contienentodocontraseñanueva">
                                                    <input  className={`crearinputcontraseña ${error.password && 'input-error'} ${passwordError && 'input-error'}`} type={showPasswordcorrect? 'text' : 'password'} name="confirm_new_password" onChange={(e) => { handleChange(e); handleChangeRepetirContraseña(e); }}  value={password.confirm_new_password} />
                                                    <span className="togglenuevopassword" onClick={togglePasswordcorrectVisibility}>
                                                        {showPasswordcorrect? <FaRegEyeSlash /> : <FaRegEye />}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                {error.password && <span className="errorcontraseñamensaje"><FiAlertCircle className='icon-error' />{error.password}</span>}
                                                {passwordError && <span className="errorcontraseñamensaje"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <section className="botonguardarcontraseñanuevacreado">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? <CircularProgress size={24} /> : 'Guardar cambios'}
                                        </button>
                                    </section>
                                </form>
                            </Box>
                        )}
                  
               
           
            <Modal open={showModals} onClose={handleCloseModals}>
                <Box sx={{
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 250,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    p: 4,
                    textAlign: 'center',
                    '@media (max-width: 340px)': {
                        width: '80%',
                        p: 2,
        
                      },
                      
                }}>
                    <Typography variant="h6" component="h2" sx={{ color: '#585857', fontSize: '1rem', '@media (max-width: 340px)': {
                        fontSize:"0.7rem"
                      }, }}>
                        Contraseña actual correcta
                    </Typography>
                    <section className="botonguardarcreado">
                        <button onClick={handleCloseModals} sx={{ mt: 2 }}>Aceptar</button>
                    </section>
                </Box>
            </Modal>
          
            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
        </>
    );
};


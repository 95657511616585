import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import logofamilia from "../../Image/generales/grupo-familia-2.png";
 import "../../Style/paginavista/familiamemorial.css" 
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import axios from "axios";
import { Helmet } from 'react-helmet';
import { CircularProgress} from "@mui/material";

/* imagenes */
import herramientas from '../../Image/generales/memorial-en-proceso.png'


function GrupoFamiliar() {
  const { grupofamiliarId } = useParams();
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [link, setLink] = useState(null); 
  const [data, setData] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [funerariaId, setFunerariaId] = useState(null);
  const [funerarias, setFunerarias] = useState(null);
  const [loading, setLoading] = useState(true);
  const [grupoUser, setGrupoUser] = useState(null);

  useEffect(() => {
    const fetchGrupoFamiliar = async () => {
      if (!grupofamiliarId) {
        navigate('/Registro');
        return;
      }

      try {
        const response = await axios.get(`${backendURL}/api/get/GrupoFamiliar/public/${grupofamiliarId}`);
        const grupoFamiliarData = response.data;

        // Si no existe el grupo familiar, redirigir a /Registro
        if (!grupoFamiliarData || grupoFamiliarData.estado === undefined) {
          navigate('/Registro');
          return;
        }

        setData(grupoFamiliarData);
        setLoading(false);

        // Redirigir si el grupo familiar está inactivo
        if (grupoFamiliarData.estado === '0') {
          navigate('/Registro');
          return;
        }

        // Obtener el id_usuario y hacer una solicitud para obtener el estado del usuario
        const userId = grupoFamiliarData.id_usuario;
        fetchUserState(userId, grupoFamiliarData);
      } catch (error) {
        console.error('Error fetching grupo familiar:', error);
        setLoading(false);
        navigate('/Registro');
      }
    };

    // Función para obtener el estado del usuario
    const fetchUserState = async (userId, grupoFamiliarData) => {
      try {
        const response = await axios.get(`${backendURL}/api/getGrupoUserpublic/${userId}`);
        const userData = response.data;

        if (userData && userData.length > 0) {
          const user = userData[0].user[0];
          setGrupoUser(user);

          // Redirigir si el usuario está inactivo
          if (user.estado === '0') {
            console.log('Redirigiendo a /user-renew con:', { user, familia: grupoFamiliarData });
            navigate('/user-renew', {
              state: {
                user: user,
                familia: grupoFamiliarData,
              },
            });
          }
        } else {
          setGrupoUser(null);
        }
      } catch (error) {
        console.error('Error fetching user state:', error);
      }
    };

    fetchGrupoFamiliar();
  }, [backendURL, grupofamiliarId, navigate]);

  useEffect(() => {
    if (data && data.id_funeraria) {
      setFunerariaId(data.id_funeraria);
    }
  }, [data]);

  useEffect(() => {
    const fetchFuneraria = async () => {
      if (!funerariaId) {
        return;
      }

      try {
        const response = await axios.get(`${backendURL}/api/funeraria/${funerariaId}`);
        const funerariaData = response.data;
        setLink(funerariaData);

        if (funerariaData ) {
          if (funerariaData.nombre === 'smart memorial') {
            setLogoUrl(null); // No mostrar logo
          } else if (funerariaData.logo) {
            setFunerarias(funerariaData);
          setLogoUrl(funerariaData.logo);
          } else {
            setLogoUrl(null); // No hay logo disponible
          }
      
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };

    fetchFuneraria();
  }, [backendURL, funerariaId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  if (loading) {
    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}><CircularProgress color="secondary" /></Box>;
  }


  return (
    <section className="contienenpaginainiciousuario">
       <Helmet>
       <title> Familia {data ? data.nombre_familia : ''} </title>
        <meta name="description" content=" Conozca sus
historias de vida" />
        
      </Helmet>
      {logoUrl && (
          <Link to={link.web} className="logofunerariadosmemorial " target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}

       <Container component="main" maxWidth="xs" >
      <Box
        sx={{
          marginTop: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',

        }}
      >
        <div>
          
            
              <div className="titulogrupofamilia">
                <h3>Grupo Familiar</h3>
                <div className="segundotitulofinal">
                <h1>{data?.nombre_familia}</h1>
                <img src={logofamilia} alt="familia smart memorial" className="logosegundotitulofinal" /></div>
              </div>
           
        
        </div>
      </Box>

      <div className="rowmemorialesfamiliapagina">
      {data && data.memorial && data.memorial.length > 0 && (
         <div className="titulomemorialestodo">
        <h1>Conozca sus  </h1>
        <h1> historias de vida</h1>
        <span>haga click  sobre la foto  para visitar el memorial</span>
      </div>
    )}

      <div className="contienentodoslosmemorialesfamilia">
      <Box sx={{width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      {data && data.memorial && data.memorial.length > 0 ? (
          data.memorial.map((item, index) => (
          <Grid container  key={item.id} sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
         <Link 
         style={{width:"100%"}}
      to={{
        pathname: `/familiar/memorial/${item?.id}`,
        state: { funeraria: funerarias }
      }}
    >
              <div className="grupofamiliacard">
                <Grid item md={12} sx={{ width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' } } >
              
            <div className="grupofamiliacircle">
              {item && (
                <img
                  src={`${backendURL}/imagenes/memoriales/${item?.carpeta_perfil}/${item?.imagen_perfil}`}
                  alt="smart memorial"
                  className="grupofamiliaprofileimg"
                  style={{
                    borderColor: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#E1EDDD';
                        case 'atardecerVerano':
                          return '#F9E7D7';
                        default:
                          return '#E1DBE6';
                      }
                    })()
                  }}
                />
              )} </div>
              </Grid>
              <Grid item md={12} sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"-82px"}}>
              <div
                className="grupofamiliacuadrado"
                style={{
                  background: (() => {
                    switch (item?.fondo) {
                      case 'naturalezaAustral':
                        return '#E1EDDD';
                      case 'atardecerVerano':
                        return '#F9E7D7';
                      default:
                        return '#E1DBE6';
                    }
                  })()
                }}
              >
                <div
                  className="grupofamiliacontent"
                  style={{
                    color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return '#D26999';
                      }
                    })()
                  }}
                >
                  <h2 className="name"style={{
                    color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return '#D26999';
                      }
                    })()
                  }}>{item?.nombre}</h2>
                  <p className="dates"  style={{
                     color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#68A354';
                        case 'atardecerVerano':
                          return '#E28939';
                        default:
                          return '#694B81';
                      }
                    })(),
                  }}>
                    {formatDate(item?.fecha_nacimiento)} • {formatDate(item?.fecha_defuncion)}
                  </p>
                </div>
              </div>
           
          
               
             </Grid>
             </div>
         </Link>
         </Grid>
        ))):(

          <Box
          sx={{
            marginTop:'20px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column',
            width:'80%',
            background:"#F0EDF3",
            borderRadius:"10px",
            padding:"30px 30px",
            '@media (max-width: 375px)': {
                    padding:"30px 10px",
                    width:'95%',
          },
          }}>
              <div className="memorialfamiliavacio">
                <h1>Este grupo familiar se encuentra</h1>
                <h1> en proceso de creación</h1>
              </div>
              <div className="contienenimagenvaciomemorial">
                <img src={herramientas} alt="smart memorial" title="smart memorial"/>
              </div>
              <div className="titulosecindariovaciomemorial"> Por favor intente más tarde</div>

          </Box>
          
          

        )}
        </Box>
      </div>
    </div> 
    </Container>
  </section>
  );
}

export default GrupoFamiliar;

import React from "react";
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container } from "@mui/material";
import axios from 'axios';
import Box from "@mui/material/Box";
import { AiOutlineCheckCircle } from "react-icons/ai";
import "../../../../Style/usuario/final.css"
import { BsFillShareFill } from "react-icons/bs";
import { FaRegEye } from 'react-icons/fa';
import { CgClose } from "react-icons/cg";

export const Finalcrearmemorial = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const ClientToken = location.state.user; // Obtener la información del usuario desde las props
    const grupoFamiliar = location.state.familia;
    const memorialId = location.state.memorial;
    const [link, setLink] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchFuneraria = async () => {
            try {
                if (grupoFamiliar && grupoFamiliar.id_funeraria) {
                    const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
                    const funerariaData = response.data;
                    setLink(funerariaData);
                    if (funerariaData.nombre === 'smart memorial') {
                        setLogoUrl(null); // No mostrar logo
                      } else if (funerariaData.logo) {
                      setLogoUrl(funerariaData.logo);
                      } else {
                        setLogoUrl(null); // No hay logo disponible
                      }
                  
                } else {
                    console.error('Group family or funerary ID is missing');
                }
            } catch (error) {
                console.error('Error fetching funeraria data:', error);
            }
        };

        fetchFuneraria();
    }, [grupoFamiliar]);

    const downloadQR = async (grupoFamiliar) => {
        console.log(grupoFamiliar);
        try {
            const response = await axios.post(`${backendURL}/api/downloadQrGeneral`, {
                qr: grupoFamiliar.qr,
                id: grupoFamiliar.id
            }, {
                responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
            });
    
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = grupoFamiliar.qr; // Nombre del archivo
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };

    const handleClick = () => {
        const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId };
        navigate(`/familiar/group/${grupoFamiliar.id}`, { state });
    };
    const handleClickinicio = () => {
        const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId };
        navigate('/usuariosmart', { state });
    };

    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Compartir Memorial',
                    text: 'Conoce nuestro memorial',
                    url: `${window.location.origin}/familiar/group/${grupoFamiliar.id}`
                });
            } else {
                throw new Error('La función de compartir no está soportada en este navegador');
            }
        } catch (error) {
            console.error('Error al compartir:', error.message);
            // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
        }
    };

    return(
        <section className="contienenpaginainiciousuario" >
        {logoUrl && (
              <Link to={link.web} className="logofunerariacrearmemorial" target="black"> 
            
             <img
               src={`${backendURL}/${logoUrl}`}
               alt="Logo de la funeraria"
               className="logofun"
    
             />
           </Link>
            )}
        <Container  component="main" maxWidth="xs">
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <section className="closeqr">
                                        <button className="closesr" onClick={handleClickinicio}><CgClose /></button>
                                    </section>

                                    <section className="tituloprinicpalgenerarqr">

<AiOutlineCheckCircle />
<h1>Su memorial ha sido</h1>
<h1>creado con éxito</h1>

                                    </section>
                                    <section className="vistaqr">
                                        <div className="titulovistaqr">
                                            <h2>Comparta el memorial con</h2>
                                            <h2>sus seres queridos</h2>
                                        </div>
                                        <div className="mantienevistaqr">
                                        <div className="contand"></div>
                                        <div className="contdos"></div>
                                        <div className="trescont"></div>
                                        <div className="cuatroqr"></div>
                                            
                                            <div className="cardqrimagen">
                                            {grupoFamiliar&&(    
                                            <img src={`${backendURL}/imagenes/qr/${grupoFamiliar.qr}`} className="imagenqrmemorial" alt="smart memorial"/>
                                           )} </div>
                                        </div>
                                    </section>
                                    <section className="botonesqr">
                                        <button className="descargar" onClick={() => downloadQR(grupoFamiliar)}>
                                            Descargar memorial
                                        </button>
                                        <button className="compartir"  onClick={handleShare}>
                                        <BsFillShareFill /> Compartir memorial
                                        </button>
                                        
                                        <button className="vermemorial" onClick={handleClick}>
                                            <FaRegEye/> Ver memorial
                                        </button>
                                    </section>


                                    </Box>
                                    </Container>
        
        </section>
    )
}
import React from "react";
import { useState, useEffect } from 'react'
import { Container, Grid } from "@mui/material";
import axios from 'axios';
import Box from "@mui/material/Box";
import { AiOutlineCheckCircle } from "react-icons/ai";
import "../../../../Style/usuario/final.css"
import { CgClose } from "react-icons/cg";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebookMessenger } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { RiSendPlaneFill } from "react-icons/ri";



export const Compartirqrusuario = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [link, setLink] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const ClientToken = location.state.user; // Obtener la información del usuario desde las props
    const grupoFamiliar = location.state.familia;
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const urlToShare = `${window.location.origin}/familiar/group/${grupoFamiliar.id}`;
    const appId = '696160549269350';

    const downloadQR = async (grupoFamiliar) => {
       
        try {
            const response = await axios.post(`${backendURL}/api/downloadQrGeneral`, {
                qr: grupoFamiliar.qr,
                id: grupoFamiliar.id
            }, {
                responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
            });
    
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = grupoFamiliar.qr; // Nombre del archivo
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };

    useEffect(() => {
      const fetchFuneraria = async () => {
        try {
          if (grupoFamiliar && grupoFamiliar.id_funeraria) {
            const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
            const funerariaData = response.data;
            setLink(funerariaData);
            if (funerariaData.nombre === 'smart memorial') {
              setLogoUrl(null); // No mostrar logo
            } else if (funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
            } else {
              setLogoUrl(null); // No hay logo disponible
            }
        
          } else {
            console.error('Group family or funerary ID is missing');
          }
        } catch (error) {
          console.error('Error fetching funeraria data:', error);
        }
      };
    
      fetchFuneraria();
    }, [grupoFamiliar]); 

    const handleGoBack = () => {
        const state = { user: ClientToken, familia: grupoFamiliar };
        navigate('/usuariomemoriales', { state });
      };
    
      const handleClick = () => {
        navigate(`/familiar/group/${grupoFamiliar.id}`);
    };

    const handleWhatsAppShare = () => {
        window.open(`https://wa.me/?text=${encodeURIComponent(urlToShare)}`, '_blank');
      };
    
      const handleMessengerShare = () => {
        window.open(`https://www.facebook.com/dialog/send?link=${encodeURIComponent(urlToShare)}&app_id=${appId}`, '_blank');
      };    
    
      const handleInstagramShare = () => {
        if (navigator.share) {
          navigator.share({
            title: 'Compartir Memorial',
            text: 'Mira este memorial',
            url: urlToShare,
          }).catch((error) => console.log('Error compartiendo', error));
        } else {
          alert('La API de Web Share no es compatible con este navegador');
        }
      };
    
      const handleGeneralShare = () => {
        if (navigator.share) {
          navigator.share({
            title: 'Compartir Memorial',
            text: 'Mira este memorial',
            url: urlToShare,
          }).catch((error) => console.log('Error compartiendo', error));
        } else {
          alert('La API de Web Share no es compatible con este navegador');
        }
      };
    
    return (
      <section className="contienenpaginainiciousuario">
      {logoUrl && (
            <Link to={link.web} className="logofunerariamemorial" target="black"> 
          
           <img
             src={`${backendURL}/${logoUrl}`}
             alt="Logo de la funeraria"
             className="logofun"
  
           />
         </Link>
          )}
            <Container  component="main" maxWidth="xs" >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                       
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <section className="closeqr">
                        <button className="closesr" onClick={handleGoBack}><CgClose /></button>
                    </section>

                    <section className="tituloprinicpalgenerarqr">
                        <h1>Comparta el memorial</h1>
                        <h1>con sus seres queridos</h1>

                    </section>
                    <section  style={{marginTop:'20px'}} className="vistacompartirqr">
                        <div className="titulovistaqr">
                        </div>
                        <div className="mantienevistaqr">
                            <div className="contand"></div>
                            <div className="contdos"></div>
                            <div className="trescont"></div>
                            <div className="cuatroqr"></div>

                            <div className="cardqrdosimagen">
                                {grupoFamiliar && (<img src={`${backendURL}/imagenes/qr/${grupoFamiliar.qr}`} className="imagenqrmemorial" alt="smart memorial" />
                                )} </div>
                        </div>
                    </section>
                    <section className="redescardcompartir">
                       <Grid container sx={{width:"100%", flexWrap:"nowrap"}}>
                        <Grid item xs={4} sx={{ margin:"5px"}}> <div className="marcored" onClick={handleWhatsAppShare}><BsWhatsapp /></div></Grid>
                        <Grid item xs={4} sx={{ margin:"5px"}}> <div className="marcored" onClick={handleMessengerShare}><FaFacebookMessenger /></div></Grid>
                        <Grid item xs={4} sx={{ margin:"5px"}}><div className="marcored" onClick={handleGeneralShare}><RiSendPlaneFill /></div></Grid>
                        </Grid>
                       
                        
                        
                    </section>
                    <section className="botonesqr">
                        <button className="compartirdescargar" onClick={() => downloadQR(grupoFamiliar)}>
                            Descargar memorial
                        </button>
                    </section>


                </Box>
            </Container>



        </section>
    )
}
import React from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import '../Style/Registro/tipomemorialpagina.css';
import { Helmet } from 'react-helmet';

/* imagenes */
import full from "../Image/generales/full.jpg"
import basico from "../Image/generales/basico.jpg"

export const Seleccionmemorial = () => {
    const navigate = useNavigate();
    const handleClickfull = () => {
        navigate('/solicitarmemorialfull'); // Navegar a la ruta de inicio
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Desplazamiento suave
        });
      };

      const handleClickbasic = () => {
        navigate('/solicitarmemorialbasico'); // Navegar a la ruta de inicio
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Desplazamiento suave
        });
      };
    return (
        <>
         <Helmet>
        <title> Bienvenido a Smart Memorial </title>
        <meta name="description" content=" Más cerca de ti" />
        
      </Helmet>
            <Container component="main" maxWidth="xs" >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <section className="titulodesolicitud">
                        <h3>Solicitar</h3>
                        <h1>Memorial</h1>
                    </section>

                    <section className="tectogenerado">
                        <span >Bienvenido(a) a Smart memorial.
                            Le agradecemos por considerar nuestro servicio de 
                            memorial para homenajear y honrar la memoria de 
                             su ser querido y nos complace ofrecerle dos opciones
                              de servicio para que pueda elegir la que mejor se adapte a sus necesidades.
                              </span>
                         
                    </section>

                    <section className="contieneseleciondememorial">
                        <section className="tituloselecion">
                            <h3> Seleccione el servicio que desea</h3>
                        </section>
                        <section>
                            <div className="recuadroinforcionmemorial">
                                <h1>Memorial Full</h1>
                                <ul>
                                    <li>
                                        Dos placa métalica con código QR para lápida o ánfora  
                                    </li>
                                    <li>Servicio memorial  digital  por 1 año</li>
                                </ul>
                            
                               <div className="solicitaryprecio">
                                 <div className="preciounasmart">
                                <div className="contienenpreciounico"> <p>$39.990</p></div>
                                 
                                </div>
                                <div className="botonsmartsolicitar" onClick={handleClickfull}>
                                    <button > Solicitar</button>
                                </div>
                                
                            </div>
                            <div style={{width:"100%", height:"100%", marginTop:"10px"}}>
                                <img src={full} alt="smart memorial full" style={{width:"100%", height:"100%"}}/>
                            </div>
                             
                            </div>
                            <div className="preciosmart">
                                    <span>( Después del año de servicio, deberá considerar pago  de mantención anual de <strong>8.990</strong>)</span>
                                </div>
                           
                        </section>
                        <section style={{marginTop:'20px'}}>
                            <div className="recuadroinforcionmemorial">
                                <h1>Memorial Básico</h1>
                                <ul>
                                    <li>
                                      Código QR para imprimir  
                                    </li>
                                    <li>Servicio memorial  digital  por 1 año</li>
                                </ul>
                        
                           <div className="solicitaryprecio">
                            <div className="preciounasmart">
                                <div className="contienenpreciounico"> <p>$15.990</p></div>
                                                                 </div>
                              
                                <div className="botonsmartsolicitar" onClick={handleClickbasic}>
                                    <button> Solicitar</button>
                                </div>
                            </div>
                            <div style={{width:"100%", height:"100%", marginTop:"10px"}}>
                                <img src={basico} alt="smart memorial full" style={{width:"100%", height:"100%"}}/>
                            </div>
                                  </div>
                           
                            
                            <div className="preciosmart">
                                    <span>( Después del año de servicio, deberá considerar pago  de mantención anual de <strong>8.990</strong>)</span>
                                </div>
                        </section>
                        

                    </section>

                </Box>
            </Container>


        </>
    )
}
import React from "react";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container, Box, Grid, CircularProgress, Modal, Typography, Button } from "@mui/material";
import "../../../../Style/usuario/evento.css"
import { IoIosArrowBack } from "react-icons/io";
import { RxCalendar } from "react-icons/rx";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { LuClock2 } from "react-icons/lu";
import { FaRegCircleCheck } from "react-icons/fa6";

export const Editarevento = () => {
    const location = useLocation();
    const ClientToken = location.state.user;
    const grupoFamiliar = location.state.familia;
    const eventoId = location.state.eventId;
    const navigate = useNavigate();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [link, setLink] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [evento, setEvento] = useState({
        titulo: "",
        fecha: "",
        hora: "",
        descripcion: "",
        id_memorial: ""
    });

    useEffect(() => {
        const fetchEvento = async () => {
            try {
                const response = await axios.get(
                    `${backendURL}/api/evento/${eventoId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${ClientToken.token}`
                        }
                    }
                );
    
               
                setEvento(response.data)
            } catch (error) {
                // Manejar errores aquí si es necesario
                console.error('Error al obtener el evento:', error);
            }
        };
    
        fetchEvento();
    }, [eventoId]);

    useEffect(() => {
      const fetchFuneraria = async () => {
        try {
          if (grupoFamiliar && grupoFamiliar.id_funeraria) {
            const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
            const funerariaData = response.data;
            setLink(funerariaData);
            if (funerariaData.nombre === 'smart memorial') {
              setLogoUrl(null); // No mostrar logo
            } else if (funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
            } else {
              setLogoUrl(null); // No hay logo disponible
            }
        
          } else {
            console.error('Group family or funerary ID is missing');
          }
        } catch (error) {
          console.error('Error fetching funeraria data:', error);
        }
      };
    
      fetchFuneraria();
    }, [grupoFamiliar]); 

    const handleGuardarEvento = async () => {
        
        try {
            setIsLoading(true); 
              setenviandoAlert(true);
            await axios.post(
                `${backendURL}/api/update/eventos/${eventoId}`,
                evento,
                {
                    headers: {
                        Authorization: `Bearer ${ClientToken.token}`
                    }
                }
            );
            setShowModal(true); // Muestra el modal de éxito
            setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Su evento fue editado con éxito.')
        } catch (error) {
            setErrorAlertOpen(true);
        setAlertMessage('Error al editar el evento, vuelva a intentar.', error);
        setIsLoading(false);
    }
    finally{
        setenviandoAlert(false);
        setIsLoading(false);
   }
  
   // Simulación de tiempo de carga
   setTimeout(() => {
       handleCloseModals();
   }, 3000);
   // Simulación de tiempo de carga
   setTimeout(() => {
       setSuccessAlertOpen(false);
       setErrorAlertOpen(false);
   }, 2000);
    };

    
    const handleCloseModals = () => {
        setenviandoAlert(false);
        navigate('/evento', { state: { user: ClientToken } });
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEvento({ ...evento, [name]: value });
    }; 

    const handleClick = () => {
        const state = { user: ClientToken };
        navigate('/evento', { state });
    };

   

    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/evento', { state: { user: ClientToken } });
    };
    return (
      <section className="contienenpaginainiciousuario">
      {logoUrl && (
            <Link to={link.web} className="logofunerariamemorial" target="black"> 
          
           <img
             src={`${backendURL}/${logoUrl}`}
             alt="Logo de la funeraria"
             className="logofun"
  
           />
         </Link>
          )}
            <Container className="contieneregistro" component="main" maxWidth="xs">
                <Box sx={{

                    display: 'flex',
                    justifyContent: 'flex-star',
                    alignItems: 'center',
                    padding: '0px',
                    marginTop:"30px",
                   
                }}>
                    <IoIosArrowBack className="flecharetrocederevento" onClick={handleClick} />
                </Box>
                <Box sx={{
                    padding: '0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <section className="titulocrearevento">
                        <h1>Editar evento</h1>
                    </section>
                </Box>
                <Box sx={{
                    padding: '0px',
                    display: 'flex',
                    alignContent: 'flex-start',
                    flexDirection: 'column'
                }}>
                     <form >
                    <Grid container sx={{ width: '100%' }}>
                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }} >
                            <div className="inputcrearevento">
                                <label className="titulocreareventolabel" htmlFor="">Título
                                </label>
                                <input className="crearinputevento" type="text" name="titulo" value={evento.titulo} 
        onChange={handleChange} />
                            </div>
                        </Grid>
                        <Grid container sx={{ width: '100%' }}>
                            <Grid item xs={8} sx={{ width: '100%', marginBottom: '10px'}}>
                                <div className="inputcrearevento">
                                    <label className="titulocreareventolabel" htmlFor="editfecha">Fecha</label>
                                    <div className="contienencalendariocrearevento">
                                        <input
                                            type="date"
                                            className="inputeventofecha"
                                            name='fecha'
                                            value={evento.fecha} 
                                            onChange={handleChange} 
                                        />
 <div className="relojicono">
                                        <RxCalendar className="iconoeventocalendario" />
                                   </div>
                                    </div>

                                </div>
                            </Grid>
                            <Grid item xs={4} sx={{ width: '100%', marginBottom: '10px', marginTop:'1px', position:'relative',right:'0px'  }}>
                                <div className="inputcrearevento">
                                    <label className="titulocreareventolabel" htmlFor="">Hora
                                    </label>
                                    <div  className="contienenrelojcrearevento">
                                    <input className="crearinputevento" 
                                    type="time" 
                                    name="hora" 
                                    value={evento.hora} 
                                     onChange={handleChange} />
                                      <div className="relojicono">
                                     <LuClock2 className="iconoeventocalendario"/>
                                     </div>
                                </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}>
                                <div className="inputcrearevento">
                                    <label className="titulocreareventolabel" htmlFor="id_memorial">Selección Memorial</label>
                                    <select className="crearmeminputevento" name="id_memorial" value={evento.id_memorial} onChange={handleChange}>
                                    <option value="" disabled>Selecciona el memorial</option>
                                        {grupoFamiliar.memorial.map((memorial) => (
                                            <option key={memorial.id} value={memorial.id}>
                                                {memorial.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}> <div className="inputcrearevento">
                            <label className="titulocreareventolabel" htmlFor="">Descripción del evento
                            </label>
                            <textarea className="creardebinputevento" type="text"  name="descripcion" value={evento.descripcion} 
        onChange={handleChange} />
                        </div>
                        </Grid>

                    </Grid>

                    <section className="botonguardareventocreado">
                            <button type="submit" disabled={isLoading} onClick={handleGuardarEvento}>
                                {isLoading ? <CircularProgress size={24} /> : "Editar evento"}
                            </button>
                        </section>
                    </form>
                </Box>
            </Container>

         
            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>

        </section>
    )
}
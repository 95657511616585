import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/usecontext";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import useMediaQuery from '@mui/material/useMediaQuery';
import { TextField, Button, Grid } from '@mui/material';
import "../../../Style/admi/admiperfil.css";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CircularProgress } from '@mui/material';
import {  useNavigate } from "react-router-dom";


const StyledForm = styled('form')({
    '& .MuiTextField-root': {
        marginBottom: '1rem',
    },
});

export const FunerariaUpdate = ({ funeraria, fetchData, handleClose }) => {
    const { adminToken } = useAuth();
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [direccion, setDireccion] = useState("");
    const [mensajeAnual, setMensajeAnual] = useState("");
    const [web, setWeb] = useState("");
    const [logo, setLogo] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();
    const [openAlert, setOpenAlert] = useState(false);

    // Este efecto se ejecutará cuando el componente se monte y establecerá los valores del estado con los datos existentes de la funeraria
    useEffect(() => {
        if (funeraria) {
            setNombre(funeraria.nombre);
            setEmail(funeraria.email);
            setDireccion(funeraria.direccion);
            setMensajeAnual(funeraria.mensaje_anual);
            setWeb(funeraria.web);
        }
    }, [funeraria]); // Se ejecutará cada vez que funeraria cambie

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("nombre", nombre);
        formData.append("email", email);
        formData.append("direccion", direccion);
        formData.append("mensaje_anual", mensajeAnual);
        formData.append("web", web);
        formData.append("logo", logo);
        formData.append("id", funeraria.id);
        try {
            setenviandoAlert(true);
            const response = await axios.post(`${backendURL}/api/funerariasUpdate`, formData, {

                headers: {
                    'Authorization': `Bearer ${adminToken}`,
                    'Content-Type': 'multipart/form-data',
                },

            });
            if (response.status === 200) {
                
                setenviandoAlert(false);
                setSuccessAlertOpen(true);
                setAlertMessage('Editado con éxito.')
                setTimeout(() => {
                    handleClose()
                    navigate("/")
                }, 3000);
                if (fetchData) {
                    fetchData();
                }
            }
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al editar, vuelva a intentar.', error);
        }
        finally{
            setenviandoAlert(false);
       }
      
       // Simulación de tiempo de carga
       setTimeout(() => {
        setenviandoAlert(false);
       }, 3000);
       // Simulación de tiempo de carga
       setTimeout(() => {
           setSuccessAlertOpen(false);
           setErrorAlertOpen(false);
           handleClose()
       }, 5000);
        
    };
   

    const handleCloseAlert = () => {
        setOpenAlert(false);
        handleClose();
    };

    useEffect(() => {
        if (openAlert) {
            // Establecer un temporizador para cerrar el modal después de dos segundos
            const timer = setTimeout(() => {
                handleCloseAlert();
            }, 2000); // 2000 milisegundos = 2 segundos

            // Limpiar el temporizador al desmontar el componente o al actualizar openAlert
            return () => clearTimeout(timer);
        }
    }, [openAlert]);

     // Esta useEffect se ejecutará cuando openAlert cambie a false
     useEffect(() => {
        if (!openAlert) {
            fetchData(); // Actualiza los datos
        }
    }, [openAlert, fetchData]);

    const isSmallScreen = useMediaQuery('(max-width: 400px)');
    // Establecer el valor de maxWidth en función del tamaño de la pantalla
    const maxWidth = isSmallScreen ? 200 : 400;

    return (
        <section>
            <section className="tituloeditarservicio">
                <h1>Editar Servicio Smart Memorial</h1>
            </section>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Nombre"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Dirección"
                            value={direccion}
                            onChange={(e) => setDireccion(e.target.value)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Mensaje Anual"
                            value={mensajeAnual}
                            onChange={(e) => setMensajeAnual(e.target.value)}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Sitio Web"
                            value={web}
                            onChange={(e) => setWeb(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <input
                            type="file"
                            accept="image/png"
                            onChange={(e) => setLogo(e.target.files[0])}
                        />
                    </Grid>
                </Grid>
               
                <button className="buttonenviarserviciocreado" type="submit">Enviar</button>
            </form>
            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
            
            </section>
    );
}

import React, { createContext, useContext, useState } from "react";

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [ClientToken, setclientToken] = useState(null)


  
  const setUserToken = (token) => {
    setclientToken(token)
  }
 

  return (
    <UsersContext.Provider value={{ ClientToken, setUserToken }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);
import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import '../../../../Style/Registro/tipomemorialpagina.css'
import { Helmet } from 'react-helmet';
import { IoClose } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import basico from "../../../../Image/generales/basico.jpg"

export const Memorialbasico = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [formValues, setFormValues] = useState({
        nombres: '',
        apellidos: '',
        correo_electronico: '',
        telefono: '+569',
    });
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };


    const sendForm = () => {
        setenviandoAlert(true);
        axios.post(`${backendURL}/api/sendmemorialbasico`, formValues)
            .then(response => {
                setenviandoAlert(false);
                setSuccessAlertOpen(true);
                setAlertMessage('Su mensaje fue enviado con éxito, sera contactado a la brevedad  por el equipo smart memorial.');
                handleOpen();
                // Limpiar los campos del formulario
                setFormValues({
                    nombres: '',
                    apellidos: '',
                    correo_electronico: '',
                    telefono: '+569',   
                });
                // Cerrar el modal después de 3 segundos
                setTimeout(() => {
                    handleClose();
                }, 7000);
            })
            .catch(error => {
                setenviandoAlert(false);
                setErrorAlertOpen(true);
            setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
                handleOpen();
                // Limpiar los campos del formulario
                setFormValues({
                    nombres: '',
                    apellidos: '',
                    correo_electronico: '',
                    telefono: '+569',
                    direccion: ''
                });
                // Cerrar el modal después de 3 segundos
                setTimeout(() => {
                    handleClose();
                }, 7000);
            });
    };
    return (
        <Container component="main" maxWidth="xs" >
              <Helmet>
        <title> Pack memorial básico  </title>
        <meta name="description" content=" Smart memorial pack memorial básico" />
        
      </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >
                <section>
                    <Link to="/solicitarmemorial">
                    <IoIosArrowBack  className="icononback"/>
                    </Link>
                </section>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <section className="titulomemorialselecion"> <h1> Memorial Básico</h1></section>
                    <section className="contieneniformacionmemorialselecion">
                        <span>Memorial básico: el servicio contempla 
                            memorial digital dónde podrá imprimir el código QR 
                            cuando desee y en la materialidad que prefiera, 
                             para ser adherido a la lápida o colgar en ánfora respectivamente. </span>
                    </section>
                    <section className="contieneinformacionmemorialselecion">
                    <div className="recuadroinfomemorial">
                               <h3>Incluye:</h3>
                               <ul>
                                    <li>
                                        Código QR digital para imprimir 
                                    </li>
                                    <li>Servicio memorial  digital  por 1 año</li>
                                </ul>
                                <div className="preciosmartmemorial">
                                <div className="unicoprecio"> <p>$15.990</p></div>
                                 
                                </div>
                                <div style={{width:"100%", height:"100%", marginTop:"10px"}}>
                                <img src={basico} alt="smart memorial full" style={{width:"100%", height:"100%"}}/>
                            </div>
                            </div>
                            <div className="preciosmart">
                                    <span>( Después del año de servicio, deberá considerar pago  de mantención anual de <strong>8.990</strong>)</span>
                                </div>
                            <form style={{width:'100%', marginTop:'30px'}} onSubmit={(e) => { e.preventDefault(); sendForm(); }}>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="nombres"> Nombres</label>
                                    <input type="text"  id="nombres" name="nombres" className="inputgenerarcompramemorial"  value={formValues.nombres} onChange={handleInputChange}  />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="apellidos"> Apellidos</label>
                                    <input type="text"  id="apellidos" name='apellidos' className="inputgenerarcompramemorial"  value={formValues.apellidos} onChange={handleInputChange} />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="correo_electronico"> Correo electrónico</label>
                                    <input type="text"  id="correo_electronico" name='correo_electronico' className="inputgenerarcompramemorial" value={formValues.correo_electronico} onChange={handleInputChange} />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="telefono">Número de celular</label>
                                    <input type="text" id='telefono' name='telefono' className="inputgenerarcompramemorial"  value={formValues.telefono} onChange={handleInputChange} />
                                </div>

                                <div className='botonsolicitarmemorial'>
                                    <button type="submit"> Solicitar </button>
                                </div>
                                <div className='informcinocompramemeorial'>
                                    <span>El equipo de Smart Memorial </span>
                                    <span> se pondrá en contacto con usted</span>
                                </div>
                            </form>
                            </section>

                </Box>
            </Box>
       

            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <h2 id="modal-title" style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600',  marginTop:"-10px" }}>Mensaje enviado Correctamente</h2>
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando mensaje...
            </p>
          </section>
        </Box>
      </Modal>
        </Container>
    )
}
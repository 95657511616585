import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useAuth } from "../../context/usecontext";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import "../../../Style/admi/admiperfil.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Modal } from '@mui/material';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const QrStore = ({ idFuneraria, fetchData, handleClose }) => {
    const { adminToken } = useAuth();
    const [cantidad, setCantidad] = useState(""); // Estado para la cantidad
    const [tipoSuscripcion, setTipoSuscripcion] = useState(""); // Estado para el tipo de suscripción
    const [openAlert, setOpenAlert] = useState(false);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [progress, setProgress] = useState(0);
    const [enviandoAlertprogress, setenviandoAlertprogress] = useState(false);

    const handleGrupoFamiliarStore = async () => {
        try {
            setenviandoAlertprogress(true);
            setenviandoAlert(true);
            setProgress(0);

            const simulateUploadProgress = () => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(progressInterval);
                        return 100;
                    }
                    return prevProgress + 10;
                });
            };

            const progressInterval = setInterval(simulateUploadProgress, 800);

            const response = await fetch(`${backendURL}/api/grupoFamiliarStore`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${adminToken}`
                },
                body: JSON.stringify({
                    cantidad: cantidad,
                    id_funeraria: idFuneraria,
                    tipo_suscripcion: tipoSuscripcion 
                })
            });

            setenviandoAlert(false);
            if (response.ok) {
                setSuccessAlertOpen(true);
                setAlertMessage('Se ha generado con éxito los códigos QR.');
                fetchData(); // Llamar a la función de actualización en el componente padre
                handleClose();
            } else {
                const errorMessage = await response.text();
                setenviandoAlert(false);
                setErrorAlertOpen(true);
                setAlertMessage(`Error al crear código QR imagen`);
                setTimeout(() => {
                    setErrorAlertOpen(false);
                }, 15000);
            }
        } catch (error) {
          setenviandoAlert(false);
            setErrorAlertOpen(true);
            setAlertMessage(`Error al crear código QR dos`);
        } finally {
            setenviandoAlert(false);
            setTimeout(() => {
                setSuccessAlertOpen(false);
                setErrorAlertOpen(false);
            }, 3000);
        }
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    useEffect(() => {
        if (openAlert) {
            const timer = setTimeout(() => {
                handleCloseAlert();
                handleClose();
            }, 15000);
            return () => clearTimeout(timer);
        }
    }, [openAlert]);

    const handleCloseAlert = () => {
        setOpenAlert(false);
        handleClose();
    };

    useEffect(() => {
        if (!openAlert) {
            fetchData();
        }
    }, [openAlert, fetchData]);

    const isSmallScreen = useMediaQuery('(max-width: 400px)');
    const maxWidth = isSmallScreen ? 200 : 400;

    const handleSubmit = (e) => {
        e.preventDefault();
        handleGrupoFamiliarStore();
    };

    return (
        <div className="cantidad">
            <section className="furtherfurther">Ingresar Cantidad de QR para el Servicio</section>

            <form className="cantidadenviar" onSubmit={handleSubmit}>
                <TextField
                    className="inputcantidadqr"
                    label="Cantidad"
                    type="number"
                    value={cantidad}
                    onChange={(e) => setCantidad(e.target.value)}
                    fullWidth
                    required
                />
                <FormControl fullWidth required margin="normal">
                    <InputLabel>Tipo de Suscripción</InputLabel>
                    <Select
                        value={tipoSuscripcion}
                        onChange={(e) => setTipoSuscripcion(e.target.value)}
                        label="Tipo de Suscripción"
                    >
                        <MenuItem value={1}>Full</MenuItem>
                        <MenuItem value={2}>Básico</MenuItem>
                    </Select>
                </FormControl>
                <button className="enviarcantidadqr" type="submit">Enviar</button>
            </form>

            {/* Modal de éxito */}
            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
                            {alertMessage}
                        </p>
                    </section>
                </Box>
            </Modal>

            {/* Modal de error */}
            <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                            {alertMessage}
                        </p>
                    </section>
                </Box>
            </Modal>

            {/* Modal de carga */}
            <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section style={{ textAlign: 'center', padding: '1rem' }}>
                        <CircularProgress style={{ color: '#694B81' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                            Procesando solicitud...
                        </p>
                    </section>
                </Box>
            </Modal>

            <Modal open={enviandoAlertprogress} onClose={() => setenviandoAlertprogress(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section style={{ textAlign: 'center', padding: '1rem' }}>
                        <CircularProgressWithLabel value={progress} style={{ color: '#694B81' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                            Procesando solicitud...
                        </p>
                    </section>
                </Box>
            </Modal>
        </div>
    );
};

export default QrStore;

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const CircularWithValueLabel = () => {
    const [progress, setProgress] = useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return <CircularProgressWithLabel value={progress} />;
};
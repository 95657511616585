import React from 'react';
import { useState, useEffect } from 'react'
import axios from 'axios';
import "../../../../Style/usuario/crear-familiar.css";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Container, CircularProgress, } from "@mui/material";

/* elmentos */

import Box from "@mui/material/Box";
import { FiAlertCircle } from "react-icons/fi";


/* imagenes */
import logomemorialdos from "../../../../Image/generales/grupo-familia-2.png";





export const Usuariocrearfamilia = () => {
  const [error, setError] = useState('');
  const [groupName, setGroupName] = useState('');
  const location = useLocation();
  const grupoFamiliar = location.state?.familia; 
  const ClientToken = location.state?.user; 
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [grupoUser, setGrupoUser] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(true);
  
 
  useEffect(() => {
    const fetchGrupoUser = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/getGrupoUser/${ClientToken.userId}`, {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          }
        });
        
        const data = await response.data;
        if (data && data.length > 0) {
          setGrupoUser(data[0].user[0]);
        } else {
          setGrupoUser(null);
        }
        setLoading(false); 
        
      } catch (error) {
        console.error('Error fetching grupo familiar:', error);
        setLoading(false); 
      }
    };
  
    if (ClientToken) {
      fetchGrupoUser(); // Llamar a fetchGrupoUser solo si ClientToken está definido
    }
  }, [ClientToken, backendURL]);

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendURL}/api/updatedFamiliarName/${grupoFamiliar.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${ClientToken.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombre_familia: groupName }),
      });
  
      if (response.ok) {
        setGroupName('');
        const updatedGrupoFamiliar = { ...grupoFamiliar, nombre_familia: groupName };
        // Navegar a la siguiente página y pasar la información actualizada
        navigate('/crearmemorial', { state: { user: ClientToken, familia: updatedGrupoFamiliar }});
      } else {
        const data = await response.json();
        setError(data.message || 'Error al actualizar el nombre del grupo familiar');
      }
    } catch (error) {
      console.error('Error updating group name:', error);
      setError('Error al actualizar el nombre del grupo familiar');
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
    setError(''); // Limpiar el mensaje de error cuando se comienza a escribir
  };

  const handleNextPage = async () => {
    if (!groupName.trim()) {
      setError('Por favor, escribe el nombre del grupo familiar');
      return;
    }
    handleSaveClick();
  };

  if (loading) {
    return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop:"30px" }}><CircularProgress color="secondary" /></Box>;
}

  /* funeraria Logo traer */


  const fetchFuneraria = async () => {
    try {
      if (grupoFamiliar && grupoFamiliar.id_funeraria) {
        const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
        const funerariaData = response.data;
        setLink(funerariaData);
        if (funerariaData.nombre === 'smart memorial') {
          setLogoUrl(null); // No mostrar logo
        } else if (funerariaData.logo) {
        setLogoUrl(funerariaData.logo);
        } else {
          setLogoUrl(null); // No hay logo disponible
        }
    
      } else {
        console.error('Group family or funerary ID is missing');
      }
    } catch (error) {
      console.error('Error fetching funeraria data:', error);
    }
  };
  
  // Llama a fetchFuneraria directamente en cada renderizado del componente
  fetchFuneraria();

  return (
    <section className="contienenpaginainiciousuario">
    {logoUrl && (
          <Link to={link.web} className="logofunerariamemorial" target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}
    <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:'center'
              }}
            >
              <div className="titulo-crear-grupo-memorial">
              <h1> {grupoUser.genero === 'Femenino' ? 'Le damos la bienvenida' : grupoUser.genero === 'Masculino' ? 'Siéntete bienvenido' : 'Le damos bienvenides'},</h1>
                <h1><strong> {grupoUser.name} </strong></h1>
                <h1><strong> {grupoUser.apellido} </strong></h1>
              </div>
              <div className='contiene-imagen-grupo-memorial'>
                <img src={logomemorialdos}  alt='smart memorial' className='logogrupofamiliar'/>
                <h1> Crear Grupo Familiar </h1>
              </div>
              <div className="creacion-input-memorial">
                <div className="creacion-nombre-grupo-familiar">
                  <label className="titulo-inupt-familia">Nombre</label>
                  <input
                    name="familia"
                    className={`input-nombre-familia ${error && 'input-error'}`}
                    placeholder="Ej: Fernandéz Rojas"
                    value={groupName}
                    onChange={handleGroupNameChange}
                  />
                  {error && <p className="errormessage"><FiAlertCircle />{error}</p>}
                  <div className="terminos">
                    <FiAlertCircle /><span>El grupo familiar puede contener hasta 5 memoriales.</span>
                  </div>
                </div>
              </div>
              <div className="contienen-botones">
                <div className="boton-registro">
                  <button onClick={handleNextPage} disabled={isLoading}>  {isLoading ? <CircularProgress size={24} /> : "Crear"}</button>
                </div>
              </div>
            </Box>
            </Container>
            </section>
        
  );
}
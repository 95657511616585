import React, { useState } from "react";
import axios from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container, Box, Fab, CircularProgress } from '@mui/material';
import "../../../../Style/usuario/evento.css"
import { MdOutlineMoreHoriz } from "react-icons/md";
import { FiAlertCircle } from "react-icons/fi";
import { Add as AddIcon } from '@mui/icons-material';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { RiFileEditFill } from "react-icons/ri";
import { CgClose } from "react-icons/cg";
import { BsTrash3Fill } from "react-icons/bs";
import { Modal } from '@mui/material';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";



export const Evento = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const ClientToken = location.state.user;
    const [grupoFamiliar, setGrupoFamiliar] = useState(null);
    const [selectedOption, setSelectedOption] = useState('todos');
    const [eventos, setEventos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [eventIdToDelete, setEventIdToDelete] = useState(null);
    const [open, setOpen] = useState(false);
    const [filteredEventos, setFilteredEventos] = useState([]);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [link, setLink] = useState(null); 
    const [logoUrl, setLogoUrl] = useState('');

 
    const fetchGrupoUser = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/getGrupoUser/${ClientToken.userId}`, {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          }
        });
  
        const fetchedGrupoFamiliar = response.data[0];
        setGrupoFamiliar(fetchedGrupoFamiliar);
  
        const eventosResponse = await axios.get(`${backendURL}/api/getEventos/user`, {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          },
          params: { idGrupoFamiliar: fetchedGrupoFamiliar.id }
        });
  
        if (eventosResponse.data.message) {
          setEventos([]);
          setFilteredEventos([]);
        } else {
          setEventos(eventosResponse.data);
          applyFilter(eventosResponse.data, selectedOption);
        }
      } catch (error) {
        console.error('Error fetching grupo familiar or eventos:', error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchGrupoUser();
    }, [ClientToken, backendURL]);

     /* funeraria Logo traer */
 useEffect(() => {
  const fetchFuneraria = async () => {
    try {
      if (grupoFamiliar && grupoFamiliar.id_funeraria) {
        const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
        const funerariaData = response.data;
        setLink(funerariaData);
        if (funerariaData.nombre === 'smart memorial') {
          setLogoUrl(null); // No mostrar logo
        } else if (funerariaData.logo) {
        setLogoUrl(funerariaData.logo);
        } else {
          setLogoUrl(null); // No hay logo disponible
        }
    
      } else {
        console.error('Group family or funerary ID is missing');
      }
    } catch (error) {
      console.error('Error fetching funeraria data:', error);
    }
  };

  fetchFuneraria();
}, [grupoFamiliar]); 


  const deleteEvent = async () => {
    try {
      setenviandoAlert(true);
      const response = await axios.post(
        `${backendURL}/api/destroy/eventos`,
        { id: eventIdToDelete },
        {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          }
        }
      );
      closeDeleteModal(true);
      setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Su evento fue eliminado  con éxito.')
     await fetchGrupoUser (); 
     
    }catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al eliminar el evento, vuelva a intentar.', error);
  }
  finally{
      setenviandoAlert(false);
      closeDeleteModal(true)
      toggleDrawer(null)(false)
      setEventIdToDelete(null);
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModals();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
  

};

const handleCloseModals = () => {
  setenviandoAlert(false);
};
  

  const applyFilter = (eventos, filter) => {
    const now = new Date();
    let filtered = [];
  
    if (filter === 'proximos') {
      filtered = eventos.filter(evento => new Date(evento.fecha) >= now);
    } else if (filter === 'pasados') {
      filtered = eventos.filter(evento => new Date(evento.fecha) < now);
    } else {
      filtered = eventos;
    }
  
    setFilteredEventos(filtered);
  };

  const handleChange = (e) => {
    const filter = e.target.value;
    setSelectedOption(filter);
    applyFilter(eventos, filter);
  };

  const openDeleteModal = (eventId) => {
    setEventIdToDelete(eventId);
    setOpenModal(true);
  };
  
  const closeDeleteModal = () => {
    setEventIdToDelete(null);
    setOpenModal(false);
  };



  const handleClick = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/crearevento', { state });
  };

  const handleEditClicks = (eventId) => {
    const state = { user: ClientToken, familia: grupoFamiliar, eventId: eventId };
    navigate('/editarevento', { state });
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    
  return `${day}/${month}/${year}`;
};


  /* apertura boton */
  const toggleDrawer = (eventId) => (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSelectedEventId(eventId);
    setOpen(open);
  };
  


  const list = (
    <Box
      sx={{ width: "100%", color: "#694B81", fontSize: "2rem" }}
      role="presentation"
      onClick={() => toggleDrawer(selectedEventId)(false)}
      onKeyDown={() => toggleDrawer(selectedEventId)(false)}
    >
      {eventos.map(evento => (
        <List key={evento.id}>
          {evento.id === selectedEventId && (
            <>
              <ListItem button onClick={() => handleEditClicks(evento.id)}  >
                <ListItemIcon sx={{  minWidth:"25px", marginBottom:'5px'}}>
                  <RiFileEditFill style={{ color: '#694B81',fontSize: "1.3rem", }}  /> 
                </ListItemIcon>
                <ListItemText primary={<span style={{ color: '#694B81', fontFamily: "Poppins", fontSize: "1rem", fontWeight: "500" }}>Editar Evento</span>} /> 
              </ListItem>
              <ListItem button onClick={() => openDeleteModal(evento.id)}>
                <ListItemIcon sx={{  minWidth:"25px", marginBottom:'5px'}}>
                  <BsTrash3Fill style={{ color: '#694B81', fontSize: "1.3rem", }} />
                </ListItemIcon>
                <ListItemText primary={<span style={{ color: '#694B81', fontFamily: "Poppins", fontSize: "1rem", fontWeight: "500" }}>Eliminar evento</span>} />
              </ListItem>
            </>
          )}
        </List>
      ))}
    </Box>
  );
  
  return(
    <section className="contienenpaginainiciousuario">
    {logoUrl && (
          <Link to={link.web} className="logofunerariamemorial" target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}
    <Container className="contieneregistro" component="main" maxWidth="xs">
      
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'10px'}}>     
      <section className="titulo-eventos">
          <h1>Eventos</h1>
        </section>
      </Box>
      
        <Box sx={{
          padding: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '0px',
          '@media (max-width: 830px)': {
            marginTop: '0px'
          }
        }}>
      
        
        <section className="filtro-eventos">
          <div className="mostrarfiltro">
            <span>Mostrar</span>
            <select className="filtroeventoselect" id="filtro" value={selectedOption} onChange={handleChange}>
              <option className="selecionselectoption" value="todos"><p>Todos</p></option>
              <option className="selecionselectoption" value="proximos"><p>Próximos</p></option>
              <option className="selecionselectoption" value="pasados"><p>Pasados</p></option>
            </select>
          </div>
        </section>


        

        </Box>
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"50px"}}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {filteredEventos.length === 0 ? (
              <section className="alertaevento">
                <FiAlertCircle />
                <h1>No hay eventos</h1>
                <button onClick={handleClick}>Crear evento</button>
              </section>
            ) : (
              <Box>
              {filteredEventos.map(evento => (
                <div key={evento.id}>
                  <div className="cardeventovista">
                    <section className="vistaedicionevento">
                    <button onClick={toggleDrawer(evento.id)(true)}> <MdOutlineMoreHoriz /></button>
                    </section>
                    <h2 className="tituloeventonombre">{capitalizeFirstLetter(evento.titulo)}</h2>
                    <div className="horayfechaenvento">
                      <p className="fecha">{formatDate(evento.fecha)}</p>
                      <p className="hora">{evento.hora}hrs.</p>
                    </div>
                    <p className="nombrememorialevento">{evento.memorial.nombre}</p>
                    <p className="descripciondelevento">{capitalizeFirstLetter(evento.descripcion)}</p>
                  </div>
                </div>
              ))}
              </Box>
            )}
          </>
        )}


    
<Box
        sx={{
          position: 'fixed',
          bottom: '5px',
          right: '20px',
        }}
      >
        <Fab sx={{
          background: '#694B81',
          color: 'white',
          '&:hover': { background: '#431E62' }
        }} aria-label="add" onClick={handleClick}>
          <AddIcon />
        </Fab>
      </Box>

        </Container>
    
        <SwipeableDrawer
  anchor={"bottom"}
  open={open}
  onClose={toggleDrawer(null)(false)}
  onOpen={toggleDrawer(selectedEventId)(true)}
>
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0px' }}>
    <Button onClick={toggleDrawer(null)(false)}>
      <CgClose className="botoncierremenutodomemorial" />
    </Button>
  </Box>
  {list}
</SwipeableDrawer>

      <Modal
  open={openModal}
  onClose={closeDeleteModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box sx={{ borderRadius:'10px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 1, maxWidth: '80vw', textAlign: 'center', '@media (max-width: 600px)': {
              width: '90%',
            }, }}>
    <h2 className="tituloenventoeliminar">¿Estás seguro que deseas eliminar este evento?</h2>
      <div className="contienenbotonesdeeliminar">
    
    <button onClick={deleteEvent} className="buttondeleteevento" >
      Eliminar
    </button>
    <button onClick={closeDeleteModal} className="buttoncancelarevento" >
      Cancelar
    </button></div>
  </Box>
</Modal>
<Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
   
    </section>
  )
}
import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/usecontext";
import FunerariaStore from "./funerariaStore";
import QrGetFuneraria from "./qrGetFuneraria";
import { FunerariaUpdate } from "./funerariaUpdate";
import QrStore from "./qrStore";
import Switch from '@mui/material/Switch';
import { useNavigate } from "react-router-dom";
import { BsQrCode } from "react-icons/bs";
import { RiFileEditFill } from "react-icons/ri";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { FiPlusCircle } from "react-icons/fi";
import { PiUserListBold } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import { Container} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import "../../../Style/admi/admiperfil.css"
import { styled } from "@mui/system";
import { Paper, Table, TableBody, TableCell,  InputAdornment, TableHead, TableRow, TablePagination, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';



const StyledTableContainer = styled(Paper)({
   borderColor: "#585857",
 
})

// Estilo personalizado para las celdas de la tabla
const StyledTableCell = styled(TableCell)({
    textAlign:"center",
    fontFamily:"Poppins",
    color: "#585857",
    borderTop:"1px solid #585857",
    borderBottom:"1px solid #585857",
})


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,

    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      /* '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      }, */
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
    },
  }));

export const PerfilAdmi = () => {
    const [selectedFuneraria, setSelectedFuneraria] = useState(null);
    const [selectedFunerariaUpdated, setSelectedFunerariaUpdated] = useState(null);
    const [selectedQrStore, setSelectedQrStore] = useState(null);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(5);
    //modal//
    const [open, setOpen] = React.useState(false);
    const handleOpen = (funeraria) => {
        setSelectedFuneraria(funeraria);
        setOpen(true);
    };
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const handleOpenUpdated = (funeraria) => {
        setSelectedFunerariaUpdated(funeraria);
        setOpenUpdate(true);
    };
    const [openQrStore, setopenQrStore] = React.useState(false);
    const handleOpenQrStore = (funeraria) => {
        setSelectedQrStore(funeraria);
        setopenQrStore(true);
    };
    const handleClose = () => setOpen(false);
    const handleCloseUpdated = () => setOpenUpdate(false);
    const handleCloseQrStore = () => setopenQrStore(false);

    const { adminToken, setAdminAuthToken } = useAuth(); // Obtener token y función para establecer el token
    const [funerarias, setFunerarias] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedFunerariaId, setSelectedFunerariaId] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [filteredFunerarias, setFilteredFunerarias] = useState([]);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
        // Llama a la función para recargar los datos al abrir o cerrar el popup
    };


    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
            fetchData();
        } else {
            navigate("/"); // Si no hay token almacenado, redirige a la página de inicio de sesión
        }
    }, []);
    useEffect(() => {
        if (adminToken) {
            fetchData();
        }
    }, [adminToken]);


   



    const fetchData = async () => {
        try {
            const response = await fetch(`${backendURL}/api/funerariasGet`, {
                headers: {
                    'Authorization': `Bearer ${adminToken}`
                }
            });
            const data = await response.json();
            setFunerarias(data);
            setFilteredFunerarias(data);
            setLoading(false); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSwitchChange = async (funerariaId, estadoActual) => {
        try {
            const nuevoEstado = estadoActual === 1 ? 0 : 1;

            const response = await fetch(`${backendURL}/api/funerariasUpdateState`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${adminToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: funerariaId,
                    estado: nuevoEstado
                }),
            });

            if (!response.ok) {
                console.error('Error al actualizar el estado:', response.statusText);
            } else {
                // Actualizar el estado localmente después de una solicitud exitosa
                setFunerarias(prevFunerarias => prevFunerarias.map(fun => {
                    if (fun.id === funerariaId) {
                        return {
                            ...fun,
                            estado: nuevoEstado
                        };
                    }
                    return fun;
                }));
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        height: "auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
       padding:"50px 40px",
       height: "600px",        // Establece la altura fija a 400px
    overflow: "auto",  
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    
    const handleSearchChange = (event, value) => {
        if (typeof value === 'string') {
            setSearchValue(value);
            const filtered = funerarias.filter(funeraria => 
                funeraria.nombre.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredFunerarias(filtered);
            setPage(0);
        } else {
            setSearchValue('');
            setFilteredFunerarias(funerarias);
        }
    };

    return (
        <>
        <Container component="main" maxWidth="xl">
            <section className="tituloadministrador"> <h1>Administrador Smart Memorial</h1></section>
            
            <Box sx={{
                marginBottom:"100px",
                padding:"50px 50px",
                width: '95%',
                
                '@media (max-width: 600px)': { // Estilos aplicados para pantallas de hasta 600px de ancho
                    height: '100px',
                },
            }}>
                <section className="botoncrearmas">
                <button className="masfunerarias"  onClick={togglePopup} > <FiPlusCircle /> </button> <h1>Agregar Nuevo servicio</h1>
                    </section>
                    {loading ? (
                      <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <CircularProgress />
                </div>
                ) : (
                    <>
                    <Autocomplete
    options={funerarias.map((option) => option.nombre)}
    value={searchValue}
    sx={{width:"74%", zIndex:"1111", position:"relative", top:"51px", fontFamily:"Poppins"}}
    onChange={(event, value) => handleSearchChange(event, value)}
    renderInput={(params) => (
        <TextField
            {...params}
            label="Buscar por nombre servicio"
            variant="outlined"
            sx={{
                fontFamily:"Poppins",
                fontSize:"0.8rem",
                '& .MuiOutlinedInput-input': {
                    fontFamily:"Poppins",
                    fontSize:"0.6rem",
                }
            }}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon sx={{color:"#694B81", left:"20px"}}/>
                    </InputAdornment>
                ),
            }}
        />
    )}
/>
                <StyledTableContainer component={Paper}>
                    <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={filteredFunerarias.length} 
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
/>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" className="tablaadmi">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell className="tablaadmi">Acciones</StyledTableCell>
                                <StyledTableCell className="tablaadmi">Estado</StyledTableCell>
                                <StyledTableCell className="tablaadmi">Nombre</StyledTableCell>
                                <StyledTableCell className="tablaadmi">Email</StyledTableCell>
                                <StyledTableCell className="tablaadmi">Dirección</StyledTableCell>
                                <StyledTableCell className="tablaadmi">Logo</StyledTableCell>
                                <StyledTableCell className="tablaadmi">Mensaje Anual</StyledTableCell>
                                <StyledTableCell className="tablaadmi">Web</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredFunerarias.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((funeraria) => ( 
                                <TableRow key={funeraria.id}>
                                    <StyledTableCell component="th" scope="row">
                                        <div className="contienenbotonadmi">
                                        <button className="botonqr" onClick={() => handleOpenQrStore(funeraria.id)} > <BsQrCode/></button><br />
                                        <p>Generar código Qr</p>
                                        </div>
                                       
                                        <div className="contienenbotonadmi">
                                        <button className="editadmi" onClick={() =>
                                            handleOpenUpdated(funeraria)}> <RiFileEditFill/></button><br />
                                         <p>Editar datos</p>
                                        </div>
                                        <div className="contienenbotonadmi">
                                        <button className="botonfamilias"  onClick={() =>
                                            handleOpen(funeraria)}><PiUserListBold /></button><br />
                                            <p>Códigos Qr</p>
                                            </div>
                                           
                                            <IOSSwitch checked={funeraria.estado == "1"} onChange={() => handleSwitchChange(funeraria.id,
                                            funeraria.estado)} direction={funeraria.estado == 1 ? 'rtl' : 'ltr'}
                                        />
                                       
                                        <br />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row" sx={{textTransform:"capitalize"}}>  {funeraria.estado == "1" ? "Activo" : "Inactivo"}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row" sx={{textTransform:"capitalize"}}>{funeraria.nombre}</StyledTableCell>
                                    <StyledTableCell>{funeraria.email}</StyledTableCell>
                                    <StyledTableCell sx={{textTransform:"capitalize"}}>{funeraria.direccion}</StyledTableCell>
                                    <StyledTableCell>
                                        {funeraria.logo && (
                                            <img src={`${backendURL}/${funeraria.logo}`} alt="smart memorial"
                                                style={{ maxWidth: '150px', maxHeight: '100%', width: 'auto', height: 'auto' }} />
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>{funeraria.mensaje_anual}</StyledTableCell>
                                    <StyledTableCell>{funeraria.web}</StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
                </>
                )}

                <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={open}
                    onClose={handleClose} closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}>
                    <Fade in={open}>
                        <Box sx={style}>
                            {/* Pasa los datos de la funeraria seleccionada al componente QrGetFuneraria */}
                            <QrGetFuneraria funeraria={selectedFuneraria} />
                        </Box>
                    </Fade>
                </Modal>





                {popupVisible && (
                    <>
                        <div className="overpopuplay"></div> 
                        <div className="manejatodopopup">
                            <div className="poppup ">
                                <div className="boton-close-popup">
                                    <button onClick={togglePopup}><IoCloseOutline /></button>
                                </div>
                                <FunerariaStore fe togglePopup={togglePopup} />
                            </div>
                        </div>
                    </>
                )}

                {selectedFunerariaId !== null && (
                    <>
                        <div className="overpopuplay"></div> {/* Fondo oscuro */}
                        <div className="manejatodopopup">
                            <div className="popup grande" style={{ width: '900px' }}>
                                <div className="boton-close-popup">
                                    <button onClick={() => setSelectedFunerariaId(null)}>x</button>
                                </div>
                                <div className="contenido-popup">
                                    {/* Contenido del modal */}
                                    <QrStore idFuneraria={selectedFunerariaId} fetchData={fetchData}
                                        handleClose={setSelectedFunerariaId} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Box>
            <>
                <Box sx={{
                    width: '100%',
                    height: '40px',
                    '@media (max-width: 600px)': { // Estilos aplicados para pantallas de hasta 600px de ancho
                        height: '200px',
                    },
                }}>
                    <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
                        open={openUpdate} onClose={handleCloseUpdated} closeAfterTransition slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}>
                        <Fade in={openUpdate}>
                            <Box sx={style}>
                                {/* Pasa los datos de la funeraria seleccionada al componente QrGetFuneraria */}
                                <FunerariaUpdate funeraria={selectedFunerariaUpdated} fetchData={fetchData}
                                    handleClose={handleCloseUpdated} />
                            </Box>
                        </Fade>
                    </Modal>
                </Box>
                <Box sx={{ width: 400, bgcolor: 'background.paper', p: 1 }}>
                    <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
                        open={openQrStore} onClose={handleCloseQrStore} closeAfterTransition slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}>
                        <Fade in={openQrStore}>
                            <Box sx={style}>
                                {/* Pasa los datos de la funeraria seleccionada al componente QrGetFuneraria */}
                                <QrStore idFuneraria={selectedQrStore} fetchData={fetchData} handleClose={handleCloseQrStore} />
                            </Box>
                        </Fade>
                    </Modal>
                </Box>
                       
            </>
             </Container>
        </>
    );
};


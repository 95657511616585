import React from "react";
import "../../../../Style/usuario/crear-familiar.css";
import "../../../../Style/usuario/cometarioyfondo.css";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import { Container, Modal } from "@mui/material";
import axios from 'axios';
import Box from "@mui/material/Box";
import { CiCirclePlus } from "react-icons/ci";
import { IoIosMail } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { BsTrash3Fill } from "react-icons/bs";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FiPlusCircle } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { CircularProgress } from '@mui/material';
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { styled } from '@mui/system';



const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
color:"#585857",
fontFamily:"Poppins",
fontSize:"0.8rem",
'& .MuiTypography-root':{
    fontSize:"0.8rem",
}

}))


export const Memorialcreartres = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const ClientToken = location.state.user; // Obtener la información del usuario desde las props
    const grupoFamiliar = location.state.familia;
    const memorialId = location.state.memorial;
    const [logoUrl, setLogoUrl] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [comentarios, setComentarios] = useState([]);
    const [selectedOption, setSelectedOption] = useState('clasicoSmartMemorial');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openModals, setOpenModals] = useState(false);
    const [link, setLink] = useState(null);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const [formData, setFormData] = useState({
        nombre: '',
        titulo: '',
        descripcion: '',
        imagen: null
    });

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        updateMemorial(selectedValue);
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setFormData({
            ...formData,
            imagen: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { nombre, titulo, descripcion, imagen } = formData;

        const formDataToSend = new FormData();
        formDataToSend.append('titulo', titulo);
        formDataToSend.append('creador', nombre);
        formDataToSend.append('mensaje', descripcion);
        formDataToSend.append('imagen', imagen);
        formDataToSend.append('id_memorial', memorialId);

        try {
            setLoading(true)
            setenviandoAlert(true);
            const response = await axios.post(`${backendURL}/api/comentarioStore`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${ClientToken.token}`
                }
            });

            await axios.post(`${backendURL}/api/comentarioState`, {
                id: response.data.id, visible: 1
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ClientToken.token}`
                }
            });
            setLoading(false);
            setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su mensaje fue creado con éxito.')
            setComentarios([...comentarios, response.data]);
            setFormData({
                nombre: '',
                titulo: '',
                descripcion: '',
                imagen: null
            });

            togglePopup();
           

        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
        }
        finally{
            setenviandoAlert(false);
       }
      
       // Simulación de tiempo de carga
       setTimeout(() => {
           handleCloseModal();
       }, 3000);
       // Simulación de tiempo de carga
       setTimeout(() => {
           setSuccessAlertOpen(false);
           setErrorAlertOpen(false);
       }, 2000);
        
    };


    const handleCloseModals = () => {
        setOpenModals(false);
        setCommentToDelete(null);
      };

    const handleDeleteComment = async (id) => {
        try {
            setenviandoAlert(true);
            await axios.post(
                `${backendURL}/api/comentarioDelete`,
                { id: id },
                {
                    headers: {
                        'Authorization': `Bearer ${ClientToken.token}`
                    }
                }
            );
            setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su mensaje fue eliminado con éxito.')
      setLoading(false);
      handleCloseModals();
            const updatedComentarios = comentarios.filter(comentario => comentario.id !== id);
            setComentarios(updatedComentarios);

            setFormData({
                nombre: '',
                titulo: '',
                descripcion: '',
                imagen: null
            });

           
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al eliminar el mensaje, vuelva a intentar.');
          }
          finally{
            setenviandoAlert(false);
       }
      
       // Simulación de tiempo de carga
       setTimeout(() => {
           handleCloseModal();
       }, 3000);
       // Simulación de tiempo de carga
       setTimeout(() => {
           setSuccessAlertOpen(false);
           setErrorAlertOpen(false);
       }, 2000);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setenviandoAlert(false);
      };

    const updateMemorial = async (selectedOption) => {
        try {
            const response = await axios.post(`${backendURL}/api/updateMemorial/${memorialId}`,
                { fondo: selectedOption },
                {
                    headers: {
                        'Authorization': `Bearer ${ClientToken.token}`
                    }
                }
            );

          
        } catch (error) {
            console.error('Error al editar el memorial:', error);
        }
    };

    useEffect(() => {
        const fetchComentarios = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getMemorialId/${memorialId}`, {
                    headers: {
                        'Authorization': `Bearer ${ClientToken.token}`
                    }
                });
    
                // Verificar si response.data.comentarios es un array antes de establecer el estado
                if (Array.isArray(response.data.comentarios)) {
                    setComentarios(response.data.comentarios);
                } else {
                    console.error('La respuesta no contiene comentarios válidos:', response.data);
                }
                
                // Establecer el valor predeterminado si response.data.fondo es null o undefined
                const defaultFondo = response.data.fondo || 'clasicoSmartMemorial';
                setSelectedOption(defaultFondo);
            } catch (error) {
                console.error('Error al obtener comentarios:', error);
            }
        };
    
        fetchComentarios();
    }, [ClientToken.token, memorialId]);

    useEffect(() => {
        const fetchFuneraria = async () => {
            try {
                if (grupoFamiliar && grupoFamiliar.id_funeraria) {
                    const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
                    const funerariaData = response.data;
                    setLink(funerariaData);
                    if (funerariaData.nombre === 'smart memorial') {
                        setLogoUrl(null); // No mostrar logo
                      } else if (funerariaData.logo) {
                      setLogoUrl(funerariaData.logo);
                      } else {
                        setLogoUrl(null); // No hay logo disponible
                      }
                  
                } else {
                    console.error('Group family or funerary ID is missing');
                }
            } catch (error) {
                console.error('Error fetching funeraria data:', error);
            }
        };

        fetchFuneraria();
    }, [grupoFamiliar]);

    const handleClick = () => {
        const state = { user: ClientToken, familia: grupoFamiliar, memorial:memorialId };
        navigate('/demomemorial', { state });
      }; 

      const handleClickfinal = () => {
        const state = { user: ClientToken, familia: grupoFamiliar, memorial:memorialId };
        navigate('/qrmemoriales', { state });
      }; 

      const handleback = () => {
        const state = { user: ClientToken, familia: grupoFamiliar, memorial:memorialId };
        navigate('/crearmemorial/galeriavideo', { state });
      }; 

      const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
    
    return (
    
        <section className="contienenpaginainiciousuario" >
        {logoUrl && (
              <Link to={link.web} className="logofunerariacrearmemorial" target="black"> 
            
             <img
               src={`${backendURL}/${logoUrl}`}
               alt="Logo de la funeraria"
               className="logofun"
    
             />
           </Link>
            )}
                            <Container  component="main" maxWidth="xs">
                              <section className="contienenretoceder">
                                     <button className='boton-anterior' onClick={handleback}>
           <IoIosArrowBack />
          </button></section>


                              <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#ffff",
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                           
                                    <div className="pasosmemorial">
                                        <div className="titulopasos"><h1>PASO 4:</h1>
                                            <span>Agregue mensajes para su memorial</span>
                                        </div>
                                    </div>
                                   
                                    <div className="conitenentodoimagencomt">
                                    {Array.isArray(comentarios) && comentarios.map((comentario, index) => (
    <div className="cardmensajeaparicion" key={index}>
        <div className="basuracomt">
            <button onClick={() => {
                            setCommentToDelete(comentario.id);
                            setOpenModals(true);
                          }} >  Eliminar <BsTrash3Fill /></button>
        </div>
        <div className="toocomt">
            <div className="titulogeneradocoment">
                <h3>{capitalizeFirstLetter(comentario.titulo)}</h3>
            </div>
            <div className="comentariogeneradocomt">
                <p>{capitalizeFirstLetter(comentario.mensaje)}</p>
            </div>
            <div className="quientlorealizohora">
                <h3>{comentario.creador}</h3>  •
                <h3>{new Date(comentario.fecha).toLocaleDateString('es-ES')}</h3>
            </div>
            <div className="imagencomentario">
                {comentario.imagen && (
                    <img
                        src={`${backendURL}/${comentario.imagen}`}
                        alt="smart memorial"
                        className="imagencomentariofinal"
                    />
                )}
            </div>
        </div>
    </div>
))}
                                </div>
                                    <section className="campocomentariosingresarcliente">
                                        <div className="card-comentario">
                                            <button className="agregarcomentario" onClick={togglePopup}>
                                                <FiPlusCircle /> <p>Agregar un mensaje</p>
                                            </button>
                                        </div>
                                        {showPopup && (
                                            <div className="popupcomentario">
                                                <div className="popup-contentcomentario">
                                                    <section className="contienenboton">
                                                    <button className="close-popup-button" onClick={togglePopup}>
                                                        <IoClose />
                                                    </button></section>
                                                    <div className="titulo-comdescripcion">
                                                        <IoIosMail />
                                                        <h2>Agregar un Mensaje</h2>
                                                    </div>
                                                    <div className="formulariocoment">
                                                        <label>Ingrese su nombre</label>
                                                        <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} className="desinput" />

                                                        <label>Título</label>
                                                        <input type="text" name="titulo" value={formData.titulo} onChange={handleChange} className="desinput" />
                                                        <label>Descripción</label>
                                                        <textarea name="descripcion" value={formData.descripcion} onChange={handleChange} className="descomentario" />
                                                        <label>Agregar imágen (opcional)</label>
                                                        <div className="cardimagencom">
                                                            <div className="todocardimganecomt">
                                                                <input type="file" accept="image/*" onChange={handleImageChange} className="desimagen" />
                                                                <div className="iconoagregarcomimagen">
                                                                    <FaArrowUpFromBracket className="imgicono" />
                                                                    <span>Subir Foto</span>
                                                                </div>
                                                            </div>
                                                            {formData.imagen && (
                                                                <img src={URL.createObjectURL(formData.imagen)} alt="Vista previa de la imagen" />

                                                            )}
                                                        </div>
                                                        <button className="botonguardarcomt" onClick={handleSubmit}>  {loading ? (
    <CircularProgress size={24} color="inherit" /> // Mostrar el CircularProgress si se está guardando la imagen
  ) : (
    "Agregar mensaje"
  )}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </section>
                                    <section className="fondomemorial">
                                        <div className="titulofondomemorial">
                                            <h1>Fondo</h1>
                                        </div>
                                    </section>
                                    <section className="selecionfondo">
                                        <Select
                                            className="seleciondefondofinal"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                width:'100%',
                                                borderRadius:'50px',
                                                display:'flex',
                                                fontSize:"0.8rem",
                                                alignItems:'flex-start',
                                                color:'#585857',
                                                fontFamily:'Poppins',
                                                '& .MuiTypography-root': { // Estilos para MuiTypography-root
                                                    fontSize: '0.8rem', // Aquí estableces el tamaño de fuente deseado
                                                  }
                                              }}
                                        >
                                            <StyledMenuItem value="atardecerVerano">
                                                <div style={{ display: 'flex', alignItems:"center", justifyContent:"center" }}>
                                                   <ListItemIcon>
                                                        <div style={{ backgroundColor: '#E28939', borderRadius: '50%', width: 20, height: 20, marginTop:'5px' }} />
                                                    </ListItemIcon>
                                                     <ListItemText primary="Atardecer en verano" x={{ width: '200px', fontFamily: "Poppins", fontSize:"0.7rem", '& .MuiTypography-root': { fontSize:"0.8rem", fontFamily: "Poppins"}, '@media (max-width: 310px)': { fontSize: '0.7rem' } }}  />
                                                    
                                                </div>
                                            </StyledMenuItem>
                                            <StyledMenuItem value="naturalezaAustral">
                                                <div style={{display: 'flex', alignItems:"center", justifyContent:"center" }}>
                                                  <ListItemIcon>
                                                        <div style={{ backgroundColor: '#68A354', borderRadius: '50%', width: 20, height: 20, marginTop:'5px' }} />
                                                    </ListItemIcon>
                                                <ListItemText primary="Naturaleza austral" x={{ width: '200px', fontFamily: "Poppins", fontSize:"0.7rem", '& .MuiTypography-root': { fontSize:"0.8rem", fontFamily: "Poppins"}, '@media (max-width: 310px)': { fontSize: '0.7rem' } }}  />
                                                     </div> 
                                            </StyledMenuItem>
                                            <StyledMenuItem value="clasicoSmartMemorial">
                                                <div style={{display: 'flex', alignItems:"center", justifyContent:"center" }}>
                                                    <ListItemIcon>
                                                        <div style={{ backgroundColor: '#694B81', borderRadius: '50%', width: 20, height: 20, marginTop:'5px' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Clásico Smart Memorial" x={{ width: '200px', fontFamily: "Poppins", fontSize:"0.7rem", '& .MuiTypography-root': { fontSize:"0.8rem", fontFamily: "Poppins"}, '@media (max-width: 310px)': { fontSize: '0.7rem' } }}  />
                                                    
                                                </div>
                                            </StyledMenuItem>
                                        </Select>
                                    </section>
                                    <section className="guardarbotones">
                                   
                                        <button className="botonguardardemo" onClick={handleClick}>Previzualizar </button>
                                     
                                        <button className="botonguardarmensaje" onClick={handleClickfinal} >Crear Memorial </button>
                                
                                    </section>

                                </Box>
                            </Container>
                             <Modal
                open={openModals}
                onClose={handleCloseModals}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width:'80%', p:2
          } }}>
                  <div className="tituloeliminarmensaje"><BsTrash3Fill />
                    <h2 id="modal-title">¿Desea eliminar este mensaje?</h2></div>
                  <div className="contienetodoslosbotonesediteliminar"> <button className="noeli" onClick={handleCloseModals}>No eliminar</button>
                    <button className="sieli" onClick={() => handleDeleteComment(commentToDelete)} >{loading ?( <CircularProgress color="inherit"/>):("Si eliminar")}</button></div>
                </Box>
              </Modal>
                            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
            </section>
       
    );
}


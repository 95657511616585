import React, { useState, useEffect } from "react";
import { AppBar, Box, Container, IconButton, Toolbar, Tooltip, Drawer, List, ListItem, ListItemButton, ListItemText, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Icono para cerrar
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from "../context/usecontext";
import { useNavigate } from "react-router-dom";
import "../../Style/navbar/navbaradmi.css"
import { styled } from "@mui/system";
/* imagenes */
import logo from "../../Image/navbar/movil-smart-memorial-header.png";


const StyledAppBar = styled(AppBar)({
  background:"#431E62",
  boxShadow: "none",
  fontFamily:"Poppins"
});

const StyledDrawer = styled(Drawer)({
  fontFamily:"Poppins",
  '& .MuiPaper-root': { // Selecciona el Paper dentro del Drawer
    background: '#431E62',
    fontFamily:"Poppins" // Cambia el color de fondo
  },
});




const AnchorTemporaryDrawer = ({ open, onClose, handleLogout, adminToken }) => {

  const navigate = useNavigate();

  
  const handlecodigos = () => {
    const state = { user: adminToken, };
    navigate('/codigos', { state });
  };

  const handlegenerarcodigos = () => {
    const state = { user: adminToken, };
    navigate('/generar-codigo', { state });
  };

  const handleusers = () => {
    const state = { user: adminToken, };
    navigate('/usuariossuscritos', { state });
  };

  return (
    <StyledDrawer
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Box
         sx={{ width:400, padding: '50px 0px', background: "#431E62", color: '#fff', '@media (max-width: 400px)': {
          width: '100%',
        }, }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        id="menunavbaradmi"
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}> {/* Agregar botón de cierre */}
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          <ListItem disablePadding onClick={handlecodigos}>
            <ListItemButton>
            Smart Memorial Codigos registros
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={handlegenerarcodigos}>
            <ListItemButton>
            Buscador Codigo Qr
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={handleusers}>
            <ListItemButton>
            Usuarios suscritos
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              Soporte
            </ListItemButton>
          </ListItem>
        </List>
        <Divider sx={{ background: "#fff" }} />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <LogoutIcon sx={{ color: "#fff" }} /> Cerrar Sesión
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </StyledDrawer>
  );
};

export const Navbaradmi = ({ onLogin, onLogout }) => {
  const { adminToken, setAdminAuthToken } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    const storedToken = sessionStorage.getItem('adminToken');
    if (storedToken) {
        setAdminAuthToken(storedToken);
        const onLoginState = sessionStorage.getItem('onLogin'); // Obtener el estado de onLogin
        if (onLoginState) {
            onLogin(parseInt(onLoginState)); // Convertir a número y llamar a onLogin
        }
    } else {
        navigate("/"); // Si no hay token almacenado, redirige a la página de inicio de sesión
    }
}, []);

const handleLogout = () => {
  sessionStorage.removeItem('adminToken');
  setAdminAuthToken(null);
  sessionStorage.removeItem('onLogin'); 
  onLogout(); // Ejecutar la función onLogout
  navigate("/");
};

  return (
    <StyledAppBar position="static" className='nav'>
      
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' , marginLeft:"10px"} }}>
            <Link to='/'>
              <img className='logo-nav' src={logo} alt='smar memorial' />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                size="large"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <AnchorTemporaryDrawer open={drawerOpen} onClose={toggleDrawer(false)} handleLogout={handleLogout} id="maria" adminToken={adminToken} />
        </Toolbar>

    </StyledAppBar>
  );
};
import React from "react";
import "../../Style/generales/louding.css"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'

/* imagenes */
import logo from "../../Image/generales/movil-smart-memorial-header.png"

export const Louding = () => {
    return(
        <section  className="loudingsmartmemorial">
        <div className="smartmemorial">

      <CircularProgress sx={{marginBottom:'50px'}}/>
    </div>
        </section>
        
    )
}
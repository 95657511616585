import React from "react";
import axios from 'axios';
import { Container, Box } from "@mui/material";
import "../Style/generales/error.css";
import { useLocation, useNavigate, Link} from "react-router-dom";
import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';

import logo from "../Image/generales/inhabilitado.png"


export const Inactivoprivado = () => {
    const location = useLocation();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia;
  const [logoUrl, setLogoUrl] = useState('');
  const [link, setLink] = useState(null); 
  const [grupoUser, setGrupoUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {

    const fetchFuneraria = async () => {
      try {
        if (grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;
          setLink(funerariaData);
          if (funerariaData && funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
          }
        } else {
          console.error('Group family or funerary ID is missing');
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };




    fetchFuneraria();

    
  }, [grupoFamiliar]);

  useEffect(() => {
    const fetchGrupoUser = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/getGrupoUser/${ClientToken.userId}`, {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          }
        });
         
        const data = await response.data;
        if (data && data.length > 0) {
          setGrupoUser(data[0].user[0]);
        } else {
          setGrupoUser(null);
        }
        setLoading(false); // Marcar el estado como no cargando después de obtener los datos
        
      } catch (error) {
        console.error('Error fetching grupo familiar:', error);
        setLoading(false); // También necesitas marcar el estado como no cargando en caso de error
      }
    };
  
    if (ClientToken) {
      fetchGrupoUser(); // Llamar a fetchGrupoUser solo si ClientToken está definido
    }
  }, [ClientToken, backendURL]);

if (loading) {
    return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop:"30px" }}><CircularProgress color="secondary" /></Box>;
}



    return (
        <section className="contienenpaginainiciousuario" >

{logoUrl && (
          <Link to={link.web} className="logofunerariamemorial" target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}
        <Container 
        component="main"
         maxWidth="xs"
         sx={{
            
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            
          }}
          >
            
        <Box
              sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding:"25px 30px",
                width:'100%',
                  background:"#F0EDF3",
                  borderRadius:"10px",
                  '@media (max-width: 430px)': {
                    padding:"30px 30px",
          },
          '@media (max-width: 308px)': {
            padding:"20px 20px",
  }
              }}
          > 
          <div className="titulounoprivadoinactivo">
            <h3>Actualmente su usuario </h3>
            <h2> {grupoUser.name || null} {grupoUser.apellido || null}</h2>
            <h3>se encuentra inhabilitado</h3>
          </div>
          <div className="logoinabilitado">
            <img src={logo} alt="smart memorial"/>
          </div>
          <div className="titulodosprivadoinactivo"> <h3>Para seguir disfrutando de nuestros servicios sin interrupciones, te invitamos a dirigirte a la sección de suscripción en tu perfil y renovar tu suscripción.</h3></div>
     
        
        </Box>
        </Container>
        </section>
    )
}
import React, { useState, useEffect } from "react";
import { AppBar, Box, Container, IconButton, Toolbar, Tooltip, Drawer, List, ListItem, ListItemButton, ListItemText, Divider, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { GrInstagram } from "react-icons/gr";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import axios from "axios";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Icono para cerrar
import LogoutIcon from '@mui/icons-material/Logout';
import { useUsers } from "../context/userscontexts";
import { useLocation, useNavigate } from "react-router-dom";
import { FaYoutube } from "react-icons/fa";
import "../../Style/navbar/navbarcliente.css"
import { styled } from "@mui/system";
/* imagenes */
import logo from "../../Image/navbar/movil-smart-memorial-header.png";

const StyledAppBar = styled(AppBar)({
  background:"#431E62",
  boxShadow: "none",
  fontFamily:"Poppins"
});


const StyledDrawer = styled(Drawer)({
  fontFamily:"Poppins",
  '& .MuiPaper-root': { // Selecciona el Paper dentro del Drawer
    background: '#431E62',
    fontFamily:"Poppins" // Cambia el color de fondo
  },
});

const StyledListItemButton = styled(ListItemButton)({
  fontSize:"0.9rem",
  marginBottom:"0px"
});

const StyledListItemButtonfinal = styled(ListItemButton)({
  display: "flex",
   alignItems:"center",
   justifyContent:"center",
  marginBottom:"5px"
});

const StyledGrid  = styled(Grid )({
  display: "flex",
   alignItems:"center",
   justifyContent:"center",
   flexDirection:"row,",
   width:"70%"
});




const AnchorTemporaryDrawer = ({ grupoUser, open, onClose, handleLogout, cliente, logoservicio, grupoFamiliar, ClientToken}) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
 const { name, apellido } = grupoUser || {};


  const handlecrearmemorial = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/crearmemorial', { state });
  };


const handleClickterminos = () => {
  const state = { user: ClientToken, familia: grupoFamiliar };
  navigate(`/terminosycondicionessmart`, { state });
};

const handleClickperfil = () => {
  const state = { user: ClientToken, familia: grupoFamiliar };
  navigate(`/perfilsmart`, { state });
};


const handleClickevento = () => {
  const state = { user: ClientToken, familia: grupoFamiliar };
  navigate(`/evento`, { state });
};

const handleLogoClick = () => {
  const state = { user: ClientToken, familia: grupoFamiliar };
  navigate(`/usuariosmart`, { state });

};

  return (
    <StyledDrawer
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Box
         sx={{ width:400, padding: '50px 0px 0px 0px', background: "#431E62", color: '#fff', '@media (max-width: 400px)': {
          width: '100%',
        }, }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        id="menunavbaradmi"
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end',marginTop:'-20px' }}> 
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', paddingLeft:"20px" } }}>
           
              <img className='logo-navcliente' src={logo} alt='smar memorial'  onClick={handleLogoClick} />
            
          </Box>
          
          <IconButton onClick={onClose} color="inherit" sx={{paddingRight:'20px'}} >
            <CloseIcon className="iconoclosenav"  sx={{fontSize:'2rem'}}/>
          </IconButton>
        </Box>
        <List sx={{marginTop:"30px", marginLeft:"30px"}}>
        <ListItem disablePadding sx={{marginBottom:"20px"}}>
        <div className="nombreclientenav">
              {name && <h3>{name}</h3>}
              {apellido && <h3>{apellido}</h3>}
            </div>
          </ListItem>
          <ListItem disablePadding onClick={handleClickperfil} sx={{marginBottom:"10px"}}>
            <StyledListItemButton>
            Mi perfil
            </StyledListItemButton>
          </ListItem>
          {grupoUser && grupoUser.estado !== "0" && (
            <>
          <ListItem disablePadding  onClick={handlecrearmemorial} sx={{marginBottom:"10px"}} >
            <StyledListItemButton>
            Crear memorial
            </StyledListItemButton>
          </ListItem>
          
          <ListItem disablePadding onClick={handleClickevento} sx={{marginBottom:"10px"}} >
            <StyledListItemButton>
            Eventos
            </StyledListItemButton>
          </ListItem></>
          )}
          <ListItem disablePadding onClick={handleClickterminos} sx={{marginBottom:"10px"}}>
            <StyledListItemButton>
            Términos y condiciones
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton onClick={handleLogout}>
              <LogoutIcon sx={{ color: "#fff", fontSize:"1.7rem" }} /> Cerrar Sesión
            </StyledListItemButton>
          </ListItem>
        </List>
        <Divider sx={{ background: "#fff", margin:"10px 10px" }} />
        <List>
          <ListItem disablePadding>
            <StyledListItemButtonfinal >
              <div className="informacionnav">
                <span>Si necesita más información,</span>
                <span>escríbanos un correo a:</span>
                <a href="mailto:info@smartmemorial.cl">
                <button>info@smartmemorial.cl</button>
             </a> </div>
            
            </StyledListItemButtonfinal>
            </ListItem>
            <ListItem disablePadding>
            <StyledListItemButtonfinal >
              <div className="redessociales">
              <div className="tituloredesnav">
                <h3>Síguenos en:</h3>
              </div>
              <StyledGrid  >
              <Grid item xs={3} sx={{margin:"10px", width:"50%"}}  >
                 <a className="nulla" target="black" href="https://www.facebook.com/smartmemorial"> <div className="rednav"> <FaFacebook /> </div></a>
                </Grid> 
                <Grid item xs={3} sx={{margin:"10px", width:"50%"}}><a className="nulla" target="black" href="https://www.instagram.com/smart.memorial/"> <div className="rednav"> <GrInstagram/> </div></a></Grid>
               <Grid item xs={3} sx={{margin:"10px", width:"50%"}}>  <a className="nulla"  target="black"href="https://www.tiktok.com/@smart.memorial"><div className="rednav"> <FaTiktok /></div></a></Grid>
                <Grid item xs={3} sx={{margin:"10px", width:"50%"}}>  <a className="nulla" target="black" href="https://www.youtube.com/@SmartMemorial"><div className="rednav"> <FaYoutube /></div></a></Grid>
              </StyledGrid ></div>
            </StyledListItemButtonfinal>
          </ListItem>
        </List>

        {logoservicio && (
          <div className="logofuneraria">
            <img
              src={`${backendURL}/${logoservicio}`}
              alt="Logo de la funeraria"
              className="logofun"
              
            />
          </div>
        )}
      </Box>
    </StyledDrawer>
  );
};

export const Navbarcliente = ({ onLogin, onLogout }) => {
  const { ClientToken, setUserToken } = useUsers(); 
  const [grupoFamiliar, setGrupoFamiliar] = useState(null);
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [grupoUser, setGrupoUser] = useState(null);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };



  useEffect(() => {
    const storedToken = sessionStorage.getItem('ClientToken');
    if (storedToken) {
      setUserToken(JSON.parse(storedToken));
      const onLoginState = sessionStorage.getItem('onLogin');
      if (onLoginState) {
        onLogin(parseInt(onLoginState));
      }
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (ClientToken) {
      sessionStorage.setItem('ClientToken', JSON.stringify(ClientToken));
    }
  }, [ClientToken]);
  

 
  const fetchData = async (token) => {
    try {
      const response = await fetch(`${backendURL}/api/getGrupoUser/${token.userId}`, {
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
      });
      
      if (!response.ok) {
        handleLogout();
        return;
      }
  
      const data = await response.json();
      if (data && data.length > 0) {
        setGrupoFamiliar(data[0]); // Establecer el primer elemento como grupoFamilia
      setGrupoUser(data[0].user[0]);} else {
        setGrupoFamiliar(null); // Establecer como null si no hay datos
        setGrupoUser(null)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      handleLogout();
    } 
  };
  
 useEffect(() => {
    if (ClientToken) {
      fetchData(ClientToken);
    }
  }, [ClientToken]);
  
 
  
  const fetchFuneraria = async () => {
    try {
      if (grupoFamiliar && grupoFamiliar.id_funeraria) {
        const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
        const funerariaData = response.data;

        if (funerariaData) {
          // Verificar si el nombre de la funeraria es "Smart Memorial"
          if (funerariaData.nombre === 'smart memorial') {
            setLogoUrl(null); // No mostrar logo
          } else if (funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
          } else {
            setLogoUrl(null); // No hay logo disponible
          }
        }
      } else {
        console.error('Group family or funerary ID is missing');
      }
    } catch (error) {
      console.error('Error fetching funeraria data:', error);
    }
  };

  useEffect(() => {
    if (grupoFamiliar) {
      fetchFuneraria();
    }
  }, [grupoFamiliar]);


const handleLogout = () => {
  sessionStorage.removeItem('ClientToken');
  setUserToken(null);
  sessionStorage.removeItem('onLogin'); 
  onLogout(); // Ejecutar la función onLogout
  navigate("/");
};
const handleLogoClick = () => {
  const state = { user: ClientToken, familia: grupoFamiliar };
  navigate(`/usuariosmart`, { state });

};
  return (
    <StyledAppBar position="static" className='nav'>
      
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, padding:'0px 30px','@media (max-width: 450px)': {
            padding:'0px 10px'} }}>
            
              <img className='logonavcliente' src={logo} alt='smar memorial' style={{cursor:'pointer'}} onClick={handleLogoClick}/>
          
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>

          <Box sx={{ flexGrow: 0, padding:'0px 0px','@media (max-width: 450px)': {
            padding:'0px 0px'
          } }}>
            <Tooltip title="Open settings">
              <IconButton
                sx={{ fontSize:'2rem'}}
                size="large"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <AnchorTemporaryDrawer open={drawerOpen} onClose={toggleDrawer(false)} handleLogout={handleLogout}  cliente={ClientToken} logoservicio={logoUrl} ClientToken={ClientToken} grupoFamiliar={grupoFamiliar} grupoUser={grupoUser}/>
        </Toolbar>
      
    </StyledAppBar>
  );
};
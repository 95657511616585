import React, {useEffect, useState} from "react";
import { useUsers } from "../../context/userscontexts";
import { Louding } from "../../generales/louding";
/* elmentos */
import { useNavigate } from "react-router-dom";

export const PerfilUsuario = () => {
  const { ClientToken, setUserToken } = useUsers();  
  const [grupoFamiliar, setGrupoFamiliar] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;

 
  useEffect(() => {
    const storedToken = sessionStorage.getItem('ClientToken');
    if (storedToken) {
      const parsedToken = JSON.parse(storedToken);
      setUserToken(parsedToken);
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (ClientToken) {
      fetchData(ClientToken);
    }
  }, [ClientToken]);

  const fetchData = async (token) => {
    try {
      const response = await fetch(`${backendURL}/api/verifyToken`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token.token}`
        }
      });

      if (!response.ok) {
        handleLogout();
        return;
      }

      const data = await response.json();
      if (token !== null) { // Verificar que el token no sea null antes de hacer la llamada
        fetchGrupoFamiliar(token, token.userId);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      handleLogout();
    }
  };

  const fetchGrupoFamiliar = async (token, userId) => {
    try {
      const response = await fetch(
        `${backendURL}/api/getGrupoUser/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token.token}`,
          },
        }
      );
      const data = await response.json();
   
      if (data && data.length > 0) {
        const grupoFamiliar = data[0];
        setGrupoFamiliar(grupoFamiliar);
      } else {
        setGrupoFamiliar(null);
      }
    } catch (error) {
      console.error("Error fetching grupo familiar:", error);
      handleLogout();
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    setUserToken(null);
    sessionStorage.removeItem('ClientToken');
    navigate("/");
  };

  if (loading) {
    return <div style={{
      background:'#431E62',
      position:'fixed',
      zIndex:'1111111111111',
      width:"100%",
      height:"100%"

    }}><Louding/></div>;
  }
 
// Redirección
if (grupoFamiliar && grupoFamiliar.nombre_familia) {
  navigate('/usuariosmart', { state: { user: ClientToken, familia: grupoFamiliar } });
  return null; // Redirigiendo, no se renderiza nada
} else {
  navigate('/familia', { state: { user: ClientToken, familia: grupoFamiliar } });
  return null; // Redirigiendo, no se renderiza nada
}}
import React, {  useEffect } from "react";

/* importar funcion */
import Login from "../Components/Log/Login";

export const Home =  ({  onLogin }) => {
    
    return(

        <>
        <Login onLogin={onLogin}/>
        </>
    )
}
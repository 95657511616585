import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, TablePagination, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { TbFaceIdError } from "react-icons/tb";
import "../../../Style/admi/codigos.css";
import jsPDF from 'jspdf';
import { FaTrashCan } from "react-icons/fa6";
import { Modal } from '@mui/material';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { styled } from "@mui/system";

import fondo from "../../../Image/generales/carta.png";


const StyledTableContainer = styled(Paper)({
    borderColor: "#585857",
  
 })
 
 // Estilo personalizado para las celdas de la tabla
 const StyledTableCell = styled(TableCell)({
     textAlign:"center",
     fontFamily:"Poppins",
     color: "#585857",
     fontSize:"0.8rem",
     borderTop:"1px solid #585857",
     borderBottom:"1px solid #585857",
    
 })
 
export const Generarcodigo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const adminToken = location.state.user;
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [codigos, setCodigos] = useState([]);
    const [gruposfamilias, setGrupofamilias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [suscripcionFiltro, setSuscripcionFiltro] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [funerarias, setFunerarias] = useState([]);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
const [opcionesGrupos, setOpcionesGrupos] = useState([]);
    


    const fetchCodigos = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/getallcodigos`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            });
            setCodigos(response.data);
        } catch (error) {
            console.error("Error al obtener los códigos:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCodigos();
    }, [backendURL, adminToken]);

    const fetchCodigosqr = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/getAllGruposFamiliares`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            });
            setGrupofamilias(response.data);
            setOpcionesGrupos(response.data.map(grupo => ({ id: grupo.id, nombre: grupo.nombre_familia })));
        } catch (error) {
            console.error("Error al obtener los códigos:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCodigosqr();
    }, [backendURL, adminToken]);

    const downloadQR = async (row) => {
        try {
            const response = await axios.post(`${backendURL}/api/downloadQr`, {
                qr: row.qr,
                id: row.id
            }, {
                responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
            });
    
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = row.qr; // Nombre del archivo
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${backendURL}/api/funerariasGet`, {
                headers: {
                    'Authorization': `Bearer ${adminToken}`
                }
            });
            const data = await response.json();
            setFunerarias(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if (adminToken) {
            fetchData();
        }
    }, [adminToken]);


    const handleEstadoFiltroChange = (event) => {
        setEstadoFiltro(event.target.value);
    };

    const handleSuscripcionFiltroChange = (event) => {
        setSuscripcionFiltro(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownloadPDF = (grupoFamiliarId) => {
        const grupoFamiliar = gruposFamiliaresConSuscripcion.find(grupo => grupo.id == grupoFamiliarId);
        if (!grupoFamiliar || !grupoFamiliar.codigo) {
            console.error("Código no encontrado para el grupo familiar:", grupoFamiliarId);
            return;
        }

        const doc = new jsPDF();
        doc.addImage(fondo, 'png', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
        const textWidth = doc.getStringUnitWidth(grupoFamiliar.codigo) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const textHeight = doc.internal.getLineHeight() / doc.internal.scaleFactor;
        const centerY = (doc.internal.pageSize.getHeight() - textHeight) / 2 + 8;
        doc.text(grupoFamiliar.codigo, (doc.internal.pageSize.getWidth() - textWidth) / 2, centerY);
        doc.save(`codigo_${grupoFamiliar.codigo}.pdf`);
    };


    const gruposFamiliaresConFuneraria = gruposfamilias.map((grupo) => {
        const funeraria = funerarias.find(f => f.id == grupo.id_funeraria);
        return {
            ...grupo,
            funeraria_nombre: funeraria ? funeraria.nombre : null,
            funeraria_logo: funeraria ? funeraria.logo : null
        };
    });

    const gruposFamiliaresConSuscripcion = gruposFamiliaresConFuneraria.map((grupo) => {
        const suscripcion = codigos.find(codigo => codigo.id_grupo_familiar == grupo.id);
        return {
            ...grupo,
            codigo: suscripcion ? suscripcion.codigo : null,
            estado_suscripcion: suscripcion ? suscripcion.estado : null
        };
    });

    const filteredData = grupoSeleccionado
        ? gruposFamiliaresConSuscripcion.filter(row => row.id == grupoSeleccionado.id)
        : gruposFamiliaresConSuscripcion.filter((row) => {
            return (estadoFiltro === '' || row.estado_suscripcion === estadoFiltro) &&
                (suscripcionFiltro === '' || row.suscripcion === suscripcionFiltro);
        });

    return (
        <Container component="main" maxWidth="lm">
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: "column", marginTop: '30px' }}>
                <section className="titulocodigoadmi"><h1>Buscador de códigos QR</h1></section>
                <section className="informacioncodigos">
                    <p>Sección para buscar código QR con su código de registro.</p>
                    <p>Esto se genera para la seguridad del registro del usuario al usar Smart Memorial al comprar directamente de una empresa externa.</p>
                </section>

                
                <Autocomplete
                    sx={{width:"57%"}}
                    id="grupo-familiar-autocomplete"
                    options={opcionesGrupos}
                    getOptionLabel={(option) => option.id}
                    value={grupoSeleccionado}
                    onChange={(event, newValue) => {
                        setGrupoSeleccionado(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Buscar por ID de Grupo Familiar" variant="outlined" />}
                />
                

                {loading ? (
                    <CircularProgress />
                ) : (
                    <Paper>
                        <TablePagination
                            component="div"
                            count={filteredData.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                        />
                        <StyledTableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                    <StyledTableCell>Número</StyledTableCell>
                                        <StyledTableCell>Nombre de Familia</StyledTableCell>
                                        <StyledTableCell align="right">QR</StyledTableCell>
                                        <StyledTableCell align="right">Suscripción</StyledTableCell>
                                        <StyledTableCell align="right">Estado suscripción</StyledTableCell>
                                        <StyledTableCell align="right">Servicio </StyledTableCell>
                                        <StyledTableCell align="right">Descargar Qr</StyledTableCell>
                                        <StyledTableCell align="right">Descargar Código</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <TableRow key={row.id}>
                                                <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row"><div style={{textTransform:"capitalize"}}>{row.nombre_familia}</div></StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {row.qr && (
                                                        <img
                                                            src={`${backendURL}/imagenes/qr/${row.qr}`}
                                                            alt="smart memorial"
                                                            style={{ maxWidth: '100px', maxHeight: '100%', width: 'auto', height: 'auto' }}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.codigo}</StyledTableCell>
                                                <StyledTableCell align="right">{row.estado_suscripcion}</StyledTableCell>
                                                <StyledTableCell align="right"><div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", textAlign:"center", textTransform:"capitalize"}}>{row.funeraria_nombre} <>{row.funeraria_logo && (
                                                        <img
                                                            src={`${backendURL}/${row.funeraria_logo}`}
                                                            alt="logo funeraria"
                                                            style={{ maxWidth: '100px', maxHeight: '100%', width: 'auto', height: 'auto' }}
                                                        />
                                                    )}</></div></StyledTableCell>
                                                <StyledTableCell ><div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", textAlign:"center", with:
                                                        "100%"}}><button className="Btn"   onClick={() => downloadQR(row)}>
                                                <svg className="svgIcon" viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
                                                <span className="icon2"></span>
                                            </button>
                                            </div>
                                            </StyledTableCell>
                                                <StyledTableCell >
                                                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", textAlign:"center", with:
                                                        "100%"}}>
                                                <button className="Btn"  onClick={() => handleDownloadPDF(row.id)}>
                                                <svg className="svgIcon" viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
                                                <span className="icon2"></span>
                                            </button></div>
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                        
                    </Paper>
                )}
            </Box>
        </Container>
    );
};
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, TablePagination } from '@mui/material';
import { TbFaceIdError } from "react-icons/tb";
import "../../../Style/admi/codigos.css";
import { styled } from '@mui/material/styles';

import Switch from '@mui/material/Switch';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,

    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

export const Todosuser = () =>{
    const location = useLocation();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const adminToken = location.state.user;
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [suscripcionFiltro, setSuscripcionFiltro] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/users`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`
                    }
                });
                setUsers(response.data);
            } catch (error) {
                console.error("Error al obtener los códigos:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [backendURL, adminToken]);

    const handleEstadoChange = async (userId, newState) => {
        const newStatus = newState ? 'ACTIVO' : 'INACTIVO';
        try {
            const response = await axios.get(`${backendURL}/api/estadosuscripcion/${userId}/${newStatus}`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            });
            // Actualizar el estado localmente
            setUsers(users.map(user => user.id == userId ? { ...user, estado: newState ? 1 : 0 } : user));
        } catch (error) {
            console.error("Error al cambiar el estado del usuario:", error);
        }
    };

    const handleEstadoFiltroChange = (event) => {
        setEstadoFiltro(event.target.value);
    };

    const handleSuscripcionFiltroChange = (event) => {
        setSuscripcionFiltro(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredUsers = users.filter((user) => {
        return (
            (estadoFiltro === '' || user.estado === parseInt(estadoFiltro)) &&
            (suscripcionFiltro === '' || user.id_suscripcion.toString() === suscripcionFiltro)
        );
    });

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

    return (
        <>
            <Container component="main" maxWidth="md">
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: "column", marginTop: '30px' }}>
                    <section className="titulocodigoadmi"><h1>Usuarios suscritos</h1></section>
                    <section className="informacioncodigos"><p>Sección para visualizar los usuarios suscriptos a smart memorial.</p></section>
                    <Box sx={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="estado-filtro-label">Estado</InputLabel>
                            <Select
                                labelId="estado-filtro-label"
                                id="estado-filtro"
                                value={estadoFiltro}
                                label="Estado"
                                onChange={handleEstadoFiltroChange}
                            >
                                <MenuItem value=""><em>Todos</em></MenuItem>
                                <MenuItem value="1">Activo</MenuItem>
                                <MenuItem value="0">Inactivo</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredUsers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Usuario cliente</TableCell>
                                            <TableCell>Nombre usuario</TableCell>
                                            <TableCell>Estado</TableCell>
                                            <TableCell>Genero</TableCell>
                                            <TableCell>Teléfono</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Suscripción</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredUsers
                                            .filter(user => user.id_rol == 2) // Filtra usuarios con id_rol igual a 2
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Aplica la paginación
                                            .map((user) => ( // Mapea los usuarios filtrados
                                                <TableRow key={user.id}>
                                                    <TableCell component="th" scope="row">
                                                        {user.id}
                                                    </TableCell>
                                                    <TableCell>{user.name} {user.apellido}</TableCell>
                                                    <TableCell>{user.estado == 1 ? 'Activo' : 'Inactivo'}</TableCell>
                                                    <TableCell>{user.genero}</TableCell>
                                                    <TableCell>{user.telefono}</TableCell>
                                                    <TableCell>{user.email}</TableCell>
                                                    <TableCell>
                                                        <IOSSwitch
                                                            checked={user.estado == 1}
                                                            onChange={(event) => handleEstadoChange(user.id, event.target.checked)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={7} align="center">
                                                    <div className="contienenerrorcodigo">
                                                        <TbFaceIdError className="iconoerrorcodigo" />
                                                        No existen usuarios suscritos
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}
                </Box>
            </Container>
        </>
    );
};